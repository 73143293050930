import { Fragment, useEffect, useState, useRef, useCallback, useContext, useMemo } from 'react';
import { Link, useHistory, useRouteMatch, useLocation, generatePath, Redirect } from 'react-router-dom';
import { Pane, Heading, Strong, Text, Button, TextInput, Paragraph, Dialog, Tablist, SidebarTab, TickCircleIcon, Spinner, Select, Checkbox } from 'evergreen-ui';

import _ from 'lodash';
import styled from "styled-components";

import FacePH from '../images/FacePH';
import AspectRatio from './AspectRatio';
import DynamicInputField from './DynamicInputField';
import DynamicRadioButton from './DynamicRadioButton';
import DynamicTickBox from './DynamicTickBox';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'

import { useAuthUpdateUserDetails, useRequestAuthentication } from "../services/useAmplifyAuth";
import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';

import { I18n } from 'aws-amplify';


const AccountGiftAidDeclaration = () => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("AccountGiftAidDeclaration cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);
  

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState.actAsUser, userState.user]);

  const [updatedUserDetails, setUpdatedUserDetails] = useState(_.cloneDeep(userDetails));

  useEffect(() => {
    setUpdatedUserDetails(_.cloneDeep(userDetails));
  }, [userDetails]);

  const [processing, setProcessing] = useState(false);

  const userGiftAidDeclaration = useMemo(() => (
		updatedUserDetails?.records?.find((recordTransaction, index) => {
			if (recordTransaction.type === "giftAidDeclaration") {
				return recordTransaction;
			}
			else {
				return null;
			}
		})
	), [updatedUserDetails]);

  const [updatedUserGiftAidDeclaration, setUpdatedUserGiftAidDeclaration] = useState(_.cloneDeep(userGiftAidDeclaration));


  const ResponsivePane = styled(Pane)`
    text-align: right;
    @media only screen and (max-width: ${425}px) {
      text-align: left;
    }
  `;


  return (
    <Fragment>

      <Pane width="100%" marginTop={20} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane paddingTop={20} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={20} color="#283655" >{I18n.get('Gift Aid details')}</Strong>
            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This won't be part of your public profile.")}</Text>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      <Pane marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
          <Strong fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Declaration')}</Strong> 
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Pane >
              <Text fontSize={14} color="#283655" lineHeight={1} >{I18n.get("I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on all my donations it is my responsibility to pay any difference.")}</Text>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      <Pane marginBottom={10} alignItems="flex-start" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
          <Pane maxWidth={380} alignItems="center" display="flex" >
            <Pane alignItems="center" display="flex" marginTop={0} marginBottom={0} height={24} >
              <DynamicTickBox
                disabled={processing}
                completed={updatedUserGiftAidDeclaration?.hasBeenDeclared}
                checked={updatedUserGiftAidDeclaration?.hasBeenDeclared}
                onClick={() => {
                  setUpdatedUserGiftAidDeclaration({
                    ...updatedUserGiftAidDeclaration,
                    hasBeenDeclared: !updatedUserGiftAidDeclaration.hasBeenDeclared,
                  });
                }}
              />
              <Pane marginLeft={10} flex={1} marginTop={2} >
                <Text fontSize={14} color="#283655">{I18n.get("Add Gift Aid to my donations")}</Text>
              </Pane>
            </Pane>
          </Pane>

          <Pane marginTop={2} >
            <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("Please note, not all charities accept Gift Aid.")}</Text>
          </Pane>
        </Pane>
      </Pane>

      <Pane marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
          <Strong fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Title')}</Strong> 
        </ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Select 
              disabled={processing}
              
              height={40}
              width={90}
              margin={0}
              marginTop={4}
              
              defaultValue={`   `}
              name={"honorific-prefix"}
              autoComplete={"honorific-prefix"}
              type={"text"}

              placeholder={`${I18n.get('Title')}...`}
              value={updatedUserGiftAidDeclaration?.title}
              onChange={(e) => {
                // up to 4 characters
                setUpdatedUserGiftAidDeclaration({
                  ...updatedUserGiftAidDeclaration,
                  title: e.target.value === "   " ? undefined : e.target.value,
                });
              }}
            >
              <option disabled selected value="   " >{I18n.get('   ')}</option>
              <option value="Mr">{I18n.get('Mr')}</option>
              <option value="Mrs">{I18n.get('Mrs')}</option>
              <option value="Miss">{I18n.get('Miss')}</option>
              <option value="Ms">{I18n.get('Ms')}</option>
              <option value="Dr">{I18n.get('Dr')}</option>
            </Select>
          </Pane>
        </Pane>
      </Pane>

      <DynamicInputField label={I18n.get('First name')} inputName="given-name"
        type="text"
        disabled={processing}
        // description={I18n.get('Optional. It will be shown next to all of your transactions.')}
        placeholder={`${I18n.get('First name')}...`}
        value={updatedUserGiftAidDeclaration?.firstName}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 30) {
              // XXX(I18n.get('Name is too long.'));
            }
            else {
              // XXX();
            }
          }
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            firstName: value,
          });
        }}
        onBlur={(e) => {
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            firstName: updatedUserGiftAidDeclaration.firstName?.trim(),
          });
        }}
        // messageWhenInvalid={}
      />

      <DynamicInputField label={I18n.get('Surname')} inputName="family-name"
        type="text"
        disabled={processing}
        // description={I18n.get('Optional. It will be shown next to all of your transactions.')}
        placeholder={`${I18n.get('Surname')}...`}
        value={updatedUserGiftAidDeclaration?.lastName}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 30) {
              // XXX(I18n.get('Name is too long.'));
            }
            else {
              // XXX();
            }
          }
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            lastName: value,
          });
        }}
        onBlur={(e) => {
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            lastName: updatedUserGiftAidDeclaration.lastName?.trim(),
          });
        }}
        // messageWhenInvalid={}
      />

      <DynamicInputField label={I18n.get('Postcode')} inputName="postal-code"
        type="text"
        disabled={processing}
        // description={I18n.get('Optional. It will be shown next to all of your transactions.')}
        placeholder={`${I18n.get('Postcode')}...`}
        value={updatedUserGiftAidDeclaration?.postcode}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 30) {
              // XXX(I18n.get('Too long.'));
            }
            else {
              // XXX();
            }
          }
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            postcode: value,
          });
        }}
        onBlur={(e) => {
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            postcode: updatedUserGiftAidDeclaration.postcode?.trim(),
          });
        }}
        // messageWhenInvalid={}
      />

      <DynamicInputField label={I18n.get('Town')} inputName="address-level2"
        type="text"
        disabled={processing}
        // description={I18n.get('Optional. It will be shown next to all of your transactions.')}
        placeholder={`${I18n.get('Town')}...`}
        value={updatedUserGiftAidDeclaration?.town}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 30) {
              // XXX(I18n.get('Too long.'));
            }
            else {
              // XXX();
            }
          }
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            town: value,
          });
        }}
        onBlur={(e) => {
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            town: updatedUserGiftAidDeclaration.town?.trim(),
          });
        }}
        // messageWhenInvalid={}
      />

      <DynamicInputField label={I18n.get('Full home address')} inputName="street-address"
        type="text"
        disabled={processing}
        // description={I18n.get('Optional. It will be shown next to all of your transactions.')}
        placeholder={`${I18n.get('Full home address')}...`}
        value={updatedUserGiftAidDeclaration?.address}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 30) {
              // XXX(I18n.get('Too long.'));
            }
            else {
              // XXX();
            }
          }
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            address: value,
          });
        }}
        onBlur={(e) => {
          setUpdatedUserGiftAidDeclaration({
            ...updatedUserGiftAidDeclaration,
            address: updatedUserGiftAidDeclaration.address?.trim(),
          });
        }}
        // messageWhenInvalid={}
      />

      <Pane marginBottom={20} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
          <Pane >
            <Text fontSize={12} fontStyle="italic" color="#7B8B9A" lineHeight={1} >
              {I18n.get("Gift Aid is a government scheme for UK charities to reclaim the tax you have paid and gain 25% more at no cost to you. Gift Aid is reclaimed by the charity from the tax you pay for the current tax year. Your details and address are needed to identify you as a current UK taxpayer.")}
              <Text>{" "}</Text>
              <Strong fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This information will only be visible to the charity when claiming Gift Aid.")}</Strong>
              <Text>{" "}</Text>
              <Strong fontSize={12} fontStyle="italic" color={"#283655"} className="blue-link"
                is={Link}
                target="_blank"
                to={{
                  pathname: generatePath("/:page/:mode", {page: "legal", mode: "gift_aid"}),
                  // state: {},
                }}
              >
                {I18n.get('Find out more about Gift Aid.')}
              </Strong>
            </Text>
          </Pane>
          </Pane>
        </Pane>
      </Pane>


      <Pane alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          {processing ?
            <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
              <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
            </Pane>
          :
            <Pane width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap">

              <Pane flex="none" marginRight={36} >
                  <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                    disabled={processing || _.isEqual(userGiftAidDeclaration, updatedUserGiftAidDeclaration)}
                    onClick={() => {
                      setProcessing(true);

                      let updatedRecords = updatedUserDetails.records || [];
                      // let currentIndex = null;
                      let currentRecord = updatedRecords?.find((recordTransaction, index) => {
                        if (recordTransaction.type === "giftAidDeclaration") {
                          // currentIndex = index;
                          return recordTransaction;
                        }
                        else {
                          return false;
                        }
                      });

                      let updatedRecord = {
                        ...currentRecord,
                        ...updatedUserGiftAidDeclaration,
                        type: "giftAidDeclaration",
                      };

                      updatedRecord = _.omitBy(updatedRecord, _.isNil);
                      // updatedRecords.splice(currentIndex, currentIndex !== null ? 1 : 0, updatedRecord);

                      handleUpdateUserDetails({
                        userDetailsToUpdate: {
                          records: [updatedRecord],
                        },
                        onSuccess: (freshUserDetails) => {
                          setProcessing(false);
                        },
                        onError: (error) => {
                          console.error('Error: ', error);
                          setProcessing(false);
                        },
                      });
                    }}
                  >
                    {I18n.get("Save")}
                  </Button>
                </Pane>

              {_.isEqual(userGiftAidDeclaration, updatedUserGiftAidDeclaration) ? null :
                <Button justifyContent="center" paddingX={12} height={30}
                  disabled={false}
                  onClick={() => {
                    setUpdatedUserGiftAidDeclaration(_.cloneDeep(userGiftAidDeclaration));
                  }}
                >
                  {I18n.get("Cancel")}
                </Button>
              }

            </Pane>
          }
        </Pane>
      </Pane>
      

    </Fragment>
  );
}

const AccountMatchFundingDeclaration = () => {

  const isCurrent = useRef(null);
  useEffect(() => {
    isCurrent.current = true;
    return () => {
      console.log("AccountMatchFundingDeclaration cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    // console.log("userDetails updates");
    return (userState.actAsUser || userState.user);
  }, [userState]);
  

  const handleUpdateUserDetails = useAuthUpdateUserDetails();

  const [processing, setProcessing] = useState(false);

  const userMatchFundingDeclaration = useMemo(() => (
		userDetails?.records?.find((record, index) => {
			if (record.type === "matchFundingDeclaration") {
				return record;
			}
			else {
				return null;
			}
		})
	), [userDetails?.records]);

  const ResponsivePane = styled(Pane)`
    text-align: right;
    @media only screen and (max-width: ${425}px) {
      text-align: left;
    }
  `;


  return (

    !userMatchFundingDeclaration ? null :

    <Fragment>
    
      <Pane width="100%" marginTop={20} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane paddingTop={20} paddingBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Match funding details')}</Strong>
            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This won't be part of your public profile.")}</Text>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      <DynamicInputField label={I18n.get('Your work email')} inputName="email" type="email" disabled={processing} placeholder={`${I18n.get('Work email')}...`}
        // value={updatedUserDetails.email}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 150) {
              // setCurrenttEmailInvalidMessage(I18n.get('Email is too long.'));
            }
            else {
              // setCurrenttEmailInvalidMessage();
              // setUpdatedUserDetails({
              //   ...updatedUserDetails,
              //   email: temp,
              // });
            }
          }
        }}
        onBlur={(e) => {
          if (e.target.value) {
            let temp = e.target.value.trim();
            // eslint-disable-next-line no-useless-escape
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(temp)) {
              // setCurrenttEmailInvalidMessage(I18n.get('Email is not valid.'));
            }
            else {
              // setCurrenttEmailInvalidMessage();
            }
          }
        }}
        required={true}
      />

      <DynamicInputField label={I18n.get(`Your employer's email`)} inputName="email" type="email" disabled={processing} placeholder={`${I18n.get(`Employer's email`)}...`}
        // value={updatedUserDetails.email}
        onChange={(value) => {
          if (value) {
            let temp = value.trim();
            if (temp.length > 150) {
              // setCurrenttEmailInvalidMessage(I18n.get('Email is too long.'));
            }
            else {
              // setCurrenttEmailInvalidMessage();
              // setUpdatedUserDetails({
              //   ...updatedUserDetails,
              //   email: temp,
              // });
            }
          }
        }}
        onBlur={(e) => {
          if (e.target.value) {
            let temp = e.target.value.trim();
            // eslint-disable-next-line no-useless-escape
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(temp)) {
              // setCurrenttEmailInvalidMessage(I18n.get('Email is not valid.'));
            }
            else {
              // setCurrenttEmailInvalidMessage();
            }
          }
        }}
        required={true}
      />
      

    </Fragment>
  );
}

const ResponsivePane = styled(Pane)`
  text-align: right;
  @media only screen and (max-width: ${425}px) {
    text-align: left;
  }
`;

const AccountContactInformation = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountContactInformation - ${countRef.current}`);
    return () => {
      console.log("AccountContactInformation - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search)
  },[location.search]);

  const handleUpdateUserDetails = useAuthUpdateUserDetails();
  const handleRequestAuthentication = useRequestAuthentication();

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const [emailVerificationProcessing, setEmailVerificationProcessing] = useState(false);
  const [emailVerificationStatus, setEmailVerificationStatus] = useState(userDetails?.emailVerifiedUNIX ? "succeeded" : null);
  const [emailVerificationCode, setEmailVerificationCode] = useState();
  const [emailCodeExpiryUNIX, setEmailCodeExpiryUNIX] = useState();
  const [emailVerificationErrorMessage, setEmailVerificationErrorMessage] = useState();
  const [currentEmailIsValid, setCurrentEmailIsValid] = useState();
  const [currentEmailInvalidMessage, setCurrenttEmailInvalidMessage] = useState();

  const [phoneVerificationProcessing, setPhoneVerificationProcessing] = useState(false);
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(userDetails?.phoneVerifiedUNIX ? "succeeded" : null);
  const [phoneVerificationCode, setPhoneVerificationCode] = useState();
  const [phoneCodeExpiryUNIX, setPhoneCodeExpiryUNIX] = useState();
  const [phoneVerificationErrorMessage, setPhoneVerificationErrorMessage] = useState();
  const [currentDialCode, setCurrentDialCode] = useState();
  const [currentPhoneIsValid, setCurrentPhoneIsValid] = useState();


  const [updatedUserDetails, setUpdatedUserDetails] = useState({
    email: userDetails.email,
    phone: userDetails.phone,
    phoneDialCode: userDetails.phoneDialCode,
  });

  useEffect(() => {
    setUpdatedUserDetails({
      email: userDetails.email,
      phone: userDetails.phone,
      phoneDialCode: userDetails.phoneDialCode,
    });
  }, [userDetails.email, userDetails.phone, userDetails.phoneDialCode]);

  useEffect(() => {
    // to revert to current status
    setEmailVerificationStatus(userDetails.email === updatedUserDetails.email && userDetails?.emailVerifiedUNIX ? "succeeded" : null);
  }, [updatedUserDetails.email, userDetails.email, userDetails?.emailVerifiedUNIX]);

  useEffect(() => {
    // to revert to current status
    setPhoneVerificationStatus(userDetails.phone === updatedUserDetails.phone && userDetails?.phoneVerifiedUNIX ? "succeeded" : null);
  }, [updatedUserDetails.phone, userDetails.phone, userDetails?.phoneVerifiedUNIX]);

  useEffect(() => {
    if (emailVerificationCode) {
      setEmailVerificationStatus("pending");
    }
  }, [emailVerificationCode]);

  useEffect(() => {
    if (phoneVerificationCode) {
      setPhoneVerificationStatus("pending");
    }
  }, [phoneVerificationCode]);

  useEffect(() => {
    if (updatedUserDetails.email) {
      let temp = updatedUserDetails.email?.trim();
      // eslint-disable-next-line no-useless-escape
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(temp) || !temp || temp?.length > 150) {
        setCurrenttEmailInvalidMessage(I18n.get('Email is not valid.'));
        setCurrentEmailIsValid(false);
      }
      else {
        setCurrenttEmailInvalidMessage();
        setCurrentEmailIsValid(true);
      }
    }
  }, [updatedUserDetails.email]);

  useEffect(() => {
    if (queryParams.get('c') && queryParams.get('email')
      && (queryParams.get('email') !== userDetails.email || (queryParams.get('email') === userDetails.email && !userDetails?.emailVerifiedUNIX))
    ) {

      if (queryParams.get('e') > Math.round(new Date().getTime()/1000)) {
        
        setUpdatedUserDetails((prev) => ({
          ...prev,
          email: queryParams.get('email'),
        }));

        setEmailVerificationCode(queryParams.get('c'));

      }
      
    }
  }, [history, queryParams, userDetails.email, userDetails?.emailVerifiedUNIX]);

  const handleSubmitEmailForm = (e) => {
    e.preventDefault();
    e.persist();
    // console.log("handleSubmitEmailForm", e); // useful ???
  };

  const handleSubmitPhoneForm = (e) => {
    e.preventDefault();
    e.persist();
    // console.log("handleSubmitPhoneForm", e); // useful ???
  };


  return (
    <Fragment>
    
      <Pane maxWidth={570} paddingTop={20} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Contact information')}</Strong>
            <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("This won't be part of your public profile.")}</Text>
            </Pane>
            {/* <Pane >
              <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get("You can use email or phone number as your log-in.")}</Text>
            </Pane> */}
          </Pane>
        </Pane>
      </Pane>
      
      {/* Email verification */}

      {/* <DynamicInputField label={I18n.get('Email')} inputName="email" type="email" disabled={processing} placeholder={`${I18n.get('Email')}...`}
        value={updatedUserDetails.email}
        onChange={(value) => {
          let temp = value.trim();
          if (temp.length > 150) {
            setCurrenttEmailInvalidMessage(I18n.get('Email is too long.'));
            setCurrentEmailIsValid(false);
          }
          else {
            setCurrenttEmailInvalidMessage();
            setCurrentEmailIsValid(true);
            setUpdatedUserDetails({
              ...updatedUserDetails,
              email: temp,
            });
          }
        }}
        messageWhenInvalid={currentEmailInvalidMessage}
        onBlur={(e) => {
          let temp = e.target.value?.trim();
          // eslint-disable-next-line no-useless-escape
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(temp) || !temp || temp?.length > 150) {
            setCurrenttEmailInvalidMessage(I18n.get('Email is not valid.'));
            setCurrentEmailIsValid(false);
          }
          else {
            setCurrenttEmailInvalidMessage();
            setCurrentEmailIsValid(true);
          }
        }}
        required={true}
      /> */}

      {/* {!emailVerificationIsRequired ? null :
        <Pane marginTop={12} alignItems="center" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} ></ResponsivePane>
          <Pane flex={1} flexBasis={242} maxWidth={390} >
            <Pane maxWidth={380} >

              <Button fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                disabled={emailVerificationProcessing}
                type="submit"
                onClick={() => {

                  setEmailVerificationProcessing(true);
                  
                  handleUpdateUserDetails({
                    recordsToUpdate: [{
                      type: "emailVerification", // providing no accessCode triggers sending a new secret code 
                    }],
                    onSuccess: (freshUserDetails) => {
                      let queryParams = new URLSearchParams(location.search);
                      queryParams.set('type', "emailVerification");
                      queryParams.set('status', "pending");
                      queryParams.set('email', updatedUserDetails.email);

                      history.push({
                        pathname: generatePath(match.path, {...match.params, mode: "verify"}),
                        search: `?${queryParams.toString()}`
                      });
                      setEmailVerificationProcessing(false);
                    },
                    onError: (error) => {
                      console.error('Error: ', error);
                      setEmailVerificationProcessing(false);
                    },
                  });
                }}
              >
                
                {I18n.get('Verify email')}

                {!emailVerificationProcessing ? null : 
                  <Spinner marginLeft={8} flex="none" size={18} />
                }
              </Button>

            </Pane>
          </Pane>
        </Pane>
      } */}

      {/* emailVerification */}
      <form
        onSubmit={handleSubmitEmailForm} //keep
      >
        <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
            <Strong htmlFor="username-email" fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Email')}</Strong> 
          </ResponsivePane>
          <Pane flex={1} flexBasis={242} maxWidth={390} >
            <Pane maxWidth={380} alignItems="center" display="flex" >
              <TextInput
                id={"username-email"}
                name={"username"}
                autoComplete={"username"}
                type={"email"}
                disabled={emailVerificationProcessing || emailVerificationStatus === "pending"}
                margin={0}
                width={"100%"}
                height={40}
                placeholder={`${I18n.get('Email')}...`}
                value={updatedUserDetails.email}
                onChange={(e) => {
                  let temp = e.target.value?.trim();
                  if (temp?.length > 150) {
                    setCurrenttEmailInvalidMessage(I18n.get('Email is too long.'));
                    setCurrentEmailIsValid(false);
                  }
                  else {
                    setCurrenttEmailInvalidMessage();
                    setCurrentEmailIsValid(true);
                    setUpdatedUserDetails((prev) => ({
                      ...prev,
                      email: temp,
                    }));
                  }

                }}
                // onFocus={(e) => {setEmailVerificationErrorMessage()}}
                onBlur={(e) => {
                  let temp = e.target.value?.trim();
                  // eslint-disable-next-line no-useless-escape
                  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (!re.test(temp) || !temp) {
                    setCurrenttEmailInvalidMessage(I18n.get('Email is not valid.'));
                    setCurrentEmailIsValid(false);
                  }
                  else {
                    setCurrenttEmailInvalidMessage();
                    setCurrentEmailIsValid(true);
                  }
                }}

              />
              
              {emailVerificationStatus !== "succeeded" ? null :
                <TickCircleIcon size={20} color={"#47B881"} marginLeft={8} />
              }

            </Pane>
          </Pane>
        </Pane>

        {emailVerificationStatus === "pending" || emailVerificationStatus === "succeeded" ? null :
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180}></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap">

                <Pane flex="none" marginRight={36} >
                    <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className={emailVerificationProcessing ? null : "button-blue"}
                      disabled={emailVerificationProcessing || !currentEmailIsValid || !updatedUserDetails.email}
                      type="submit"
                      onClick={() => {

                        let temp = updatedUserDetails.email?.trim();
                        // eslint-disable-next-line no-useless-escape
                        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!re.test(temp) || !temp || temp?.length > 150) {
                          setCurrenttEmailInvalidMessage(I18n.get('Email is not valid.'));
                          setCurrentEmailIsValid(false);
                          return null;
                        }

                        setCurrentEmailIsValid(true);
                        setEmailVerificationErrorMessage();
                        setEmailVerificationProcessing(true);
                        
                        handleRequestAuthentication({
                          type: "emailVerification", // providing no accessCode triggers sending a new secret code
                          email: updatedUserDetails.email,
                          onSuccess: (transactionDetails) => {
                            if (transactionDetails?.error) {
                              setEmailVerificationErrorMessage(transactionDetails?.error?.message || "Something went wrong.");
                            }
                            else {
                              setEmailVerificationCode();
                              setEmailVerificationStatus(transactionDetails?.status);
                              setEmailCodeExpiryUNIX(transactionDetails?.accessExpiryUNIX);
                            }
                            setEmailVerificationProcessing(false);
                          },
                          onError: (error) => {
                            console.error("handleRequestAuthentication", error);
                            setEmailVerificationErrorMessage("Something went wrong.");
                            setEmailVerificationProcessing(false);
                          },
                        });

                      }}
                    >
                      {I18n.get('Verify email')}

                      {!emailVerificationProcessing ? null : 
                        <Spinner marginLeft={8} flex="none" size={18} />
                      }
                    </Button>
                  </Pane>

                  {userDetails.email === updatedUserDetails.email ? null :
                    <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                      disabled={emailVerificationProcessing}
                      type="button"
                      onClick={() => {
                        setUpdatedUserDetails((prev) => ({
                          ...prev,
                          email: userDetails.email,
                        }));
                      }}
                    >
                      {I18n.get("Discard")}
                    </Button>
                  }

              </Pane>
            </Pane>
          </Pane>
        }

        {emailVerificationStatus !== "pending" ? null :
          <Fragment>
            <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
              <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
                <Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
                <Strong fontSize={14} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Secret code')}</Strong> 
              </ResponsivePane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                <Pane maxWidth={380} >
                  <TextInput
                    maxWidth={120}
                    disabled={emailVerificationProcessing}
                    name={"one-time-code"}
                    type={"text"}
                    margin={0}
                    width={"100%"}
                    height={40}
                    placeholder={`${I18n.get('Secret code')}...`}
                    value={emailVerificationCode || ""}
                    onChange={(e) => {setEmailVerificationCode(e.target.value);}}
                    // onFocus={(e) => {setEmailVerificationErrorMessage()}}
                  />

                  {/* <PinInput size='lg' placeholder=''>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput> */}

                  <Pane display="flex" marginTop={4}>
                    <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get('The 6-digit code we have sent to your email.')}</Text>
                  </Pane>

                </Pane>
              </Pane>
            </Pane>
            
            {/* {!emailCodeExpiryUNIX ? null :
              <Pane alignItems="baseline" display="flex" flexWrap="wrap" marginTop={12} >
                <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
                <Pane flex={1} flexBasis={242} maxWidth={390} >
                  <Pane maxWidth={380} >
                      
                    <Strong fontSize={14} color="#EC4C47" fontWeight={500} display="inline-flex" marginRight={32} >{emailCodeExpiryUNIX}</Strong> 

                  </Pane>
                </Pane>
              </Pane>
            } */}

          </Fragment>
        }
        
        {emailVerificationErrorMessage || emailVerificationStatus !== "pending" ? null :
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap">

              <Pane flex="none" marginRight={36} >
                <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center"
                  className={emailVerificationProcessing ? null : "button-blue"}
                  disabled={!emailVerificationCode || emailVerificationProcessing}
                  type="submit"
                  onClick={() => {
                    setEmailVerificationErrorMessage();
                    setEmailVerificationProcessing(true);
                    handleUpdateUserDetails({
                      accessCode: emailVerificationCode?.replace(/\s/g,''),
                      userDetailsToUpdate: {
                        email: updatedUserDetails.email,
                      },
                      onSuccess: (freshUserDetails) => {
                        if (freshUserDetails?.emailVerifiedUNIX) {
                          setEmailVerificationStatus("succeeded");
                          setEmailVerificationCode();
                          setEmailCodeExpiryUNIX();

                          queryParams.delete('email');
                          queryParams.delete('e');
                          queryParams.delete('c');
                          history.replace({
                            search: `?${queryParams.toString()}`,
                          });

                        }
                        else {
                          setEmailVerificationErrorMessage("Something went wrong.");
                        }
                        setEmailVerificationProcessing(false);
                      },
                      onError: (error) => {
                        console.error("handleUpdateUserDetails", error);
                        if (error?.message === "Invalid access code.") {
                          setEmailVerificationErrorMessage("Invalid secret code.");
                        }
                        else {
                          setEmailVerificationErrorMessage("Something went wrong.");
                        }
                        setEmailVerificationProcessing(false);
                      },
                    });
                  }}
                >
                  {I18n.get("Submit")}

                  {!emailVerificationProcessing ? null : 
                    <Spinner marginLeft={8} flex="none" size={20} />
                  }

                </Button>
              </Pane>
              
              <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                disabled={emailVerificationProcessing}
                type="button"
                onClick={() => {
                  setUpdatedUserDetails((prev) => ({
                    ...prev,
                    email: userDetails.email,
                  }));
                  setEmailVerificationErrorMessage();
                  setEmailVerificationCode();
                  setEmailVerificationProcessing(false);
                  setEmailVerificationStatus();
                }}
              >
                {I18n.get("Cancel")}
              </Button>

              </Pane>
            </Pane>
          </Pane>
        }

        {!emailVerificationErrorMessage ? null :
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane height={40} width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap" >
                  
                <Strong fontSize={14} color="#EC4C47" fontWeight={500} display="inline-flex" marginRight={20} >
                  {I18n.get(emailVerificationErrorMessage)}
                </Strong> 

                <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                  disabled={false}
                  type="button"
                  onClick={() => {
                    setEmailVerificationErrorMessage();
                    if (emailVerificationStatus === "error") {
                      setEmailVerificationStatus("pending");
                    }
                  }}
                >
                  {I18n.get("Try again")}
                </Button>

              </Pane>
            </Pane>
          </Pane>
        }

      </form>

      


      {/* phoneVerification */}
      <form
        onSubmit={handleSubmitPhoneForm} //keep
      >

        <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
            <Strong htmlFor="username-phone" fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Phone number')}</Strong> 
          </ResponsivePane>
          <Pane flex={1} flexBasis={242} maxWidth={390} >
            <Pane maxWidth={380} alignItems="center" display="flex" >
              <Pane alignItems="center" display="flex" >
                <PhoneInput

                  id={"username-phone"}
                  name={"username"}
                  autoComplete={"username"}
                  type={"tel"} // ???

                  country={'gb'} // make to depend on localeState ???
                  // regions={'europe'}
                  countryCodeEditable={false}
                  placeholder={`${I18n.get('Phone number')}...`}
                  preferredCountries={['gb','it']}
                  preserveOrder={['onlyCountries', 'preferredCountries']}
                  disabled={phoneVerificationProcessing || phoneVerificationStatus === "pending"}
                  value={updatedUserDetails.phone || currentDialCode}
                  onChange={(value, data, event, formattedValue) => {
                    console.log("phone onChange event:", {value, data, event, formattedValue});
                    
                    let tempValue = value;
                    setCurrentDialCode(data.dialCode);
                    if (value === data.dialCode) {
                      tempValue = null;
                    }
                    setUpdatedUserDetails((prev) => ({
                      ...prev,
                      phone: `+${tempValue}`,
                      phoneDialCode: data.dialCode,
                    }));

                    const formatLength = data?.format?.replace(/(\ )|(\+)/g, '').length || 0;
                    // console.log("format length:", formatLength);
                    if (formatLength === value?.length) {
                      setCurrentPhoneIsValid(true);
                    }
                    else {
                      setCurrentPhoneIsValid(false);
                    }

                  }}
                  // onBlur={(event) => {
                  //   console.log("phone onBlur event:", event);
                  // }}
                  // isValid={(value, country, countries) => {
                  //   // if (value.match(/12345/)) {
                  //   //   return 'Invalid value: '+value+', '+country.name;
                  //   // } else if (value.match(/1234/)) {
                  //   //   return false;
                  //   // } else {
                  //   //   return true;
                  //   // }
                  // }}
                  // containerStyle={{}}
                  inputStyle={{
                    borderRadius: "3px",
                    color: "#425A70",
                    height: "40px",
                    maxWidth: "350px"
                    // margin: "0px",
                  }}
                  buttonStyle={{
                    borderTopLeftRadius: "3px",
                    borderBottomLeftRadius: "3px",
                  }}
                />
                
                {phoneVerificationStatus !== "succeeded" ? null :
                  <TickCircleIcon size={20} color={"#47B881"} marginLeft={8} />
                }

              </Pane>
            </Pane>
          </Pane>
        </Pane>

        {phoneVerificationStatus === "pending" || phoneVerificationStatus === "succeeded" ? null :
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180}></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap">

                <Pane flex="none" marginRight={36} >
                    <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className={phoneVerificationProcessing ? null : "button-blue"}
                      disabled={phoneVerificationProcessing || !currentPhoneIsValid || !updatedUserDetails.phone}
                      type="submit"
                      onClick={() => {
                        setPhoneVerificationErrorMessage();
                        setPhoneVerificationProcessing(true);

                        handleRequestAuthentication({
                          type: "phoneVerification", // providing no accessCode triggers sending a new secret code
                          phone: updatedUserDetails.phone,
                          phoneDialCode: updatedUserDetails.phoneDialCode,
                          onSuccess: (transactionDetails) => {
                            if (transactionDetails?.error) {
                              setPhoneVerificationErrorMessage(transactionDetails?.error?.message || "Something went wrong.");
                            }
                            else {
                              setPhoneVerificationCode();
                              setPhoneVerificationStatus(transactionDetails?.status);
                              setPhoneCodeExpiryUNIX(transactionDetails?.accessExpiryUNIX);
                            }
                            setPhoneVerificationProcessing(false);
                          },
                          onError: (error) => {
                            console.error(error);
                            setPhoneVerificationErrorMessage("Something went wrong.");
                            setPhoneVerificationProcessing(false);
                          },
                        });
                      }}
                    >
                      {I18n.get('Verify phone number')}

                      {!phoneVerificationProcessing ? null : 
                        <Spinner marginLeft={8} flex="none" size={18} />
                      }
                    </Button>
                  </Pane>

                  {userDetails.phone === updatedUserDetails.phone ? null :
                    <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                      disabled={phoneVerificationProcessing}
                      type="button"
                      onClick={() => {
                        setUpdatedUserDetails((prev) => ({
                          ...prev,
                          phone: userDetails.phone,
                          phoneDialCode: userDetails.phoneDialCode,
                        }));
                      }}
                    >
                      {I18n.get("Discard")}
                    </Button>
                  }

              </Pane>
            </Pane>
          </Pane>
        }

        {phoneVerificationStatus !== "pending" ? null :
          <Fragment>
            <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
              <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
                <Strong htmlFor="required-field" marginRight={2} fontSize={16} color="#EC4C47" >{'*'}</Strong>
                <Strong fontSize={14} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Secret code')}</Strong> 
              </ResponsivePane>
              <Pane flex={1} flexBasis={242} maxWidth={390} >
                <Pane maxWidth={380} >
                  <TextInput
                    maxWidth={120}
                    disabled={phoneVerificationProcessing}
                    name={"one-time-code"}
                    type={"text"}
                    margin={0}
                    width={"100%"}
                    height={40}
                    placeholder={`${I18n.get('Secret code')}...`}
                    value={phoneVerificationCode || ""}
                    onChange={(e) => {setPhoneVerificationCode(e.target.value);}}
                    // onFocus={(e) => {setPhoneVerificationErrorMessage()}}
                  />

                  {/* <PinInput size='lg' placeholder=''>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput> */}

                  <Pane display="flex" marginTop={4}>
                    <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{I18n.get('The 6-digit code we have sent to your phone number.')}</Text>
                  </Pane>

                </Pane>
              </Pane>
            </Pane>
            
            {/* {!phoneCodeExpiryUNIX ? null :
              <Pane alignItems="baseline" display="flex" flexWrap="wrap" marginTop={12} >
                <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
                <Pane flex={1} flexBasis={242} maxWidth={390} >
                  <Pane maxWidth={380} >
                      
                    <Strong fontSize={14} color="#EC4C47" fontWeight={500} display="inline-flex" marginRight={32} >{phoneCodeExpiryUNIX}</Strong> 

                  </Pane>
                </Pane>
              </Pane>
            } */}

          </Fragment>
        }

        {phoneVerificationErrorMessage || phoneVerificationStatus !== "pending" ? null :
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap">

              <Pane flex="none" marginRight={36} >
                <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center"
                  className={phoneVerificationProcessing ? null : "button-blue"}
                  disabled={!phoneVerificationCode || phoneVerificationProcessing}
                  type="submit"
                  onClick={() => {
                    setPhoneVerificationErrorMessage();
                    setPhoneVerificationProcessing(true);
                    handleUpdateUserDetails({
                      accessCode: phoneVerificationCode?.replace(/\s/g,''),
                      userDetailsToUpdate: {
                        phone: updatedUserDetails.phone,
                        phoneDialCode: updatedUserDetails.phoneDialCode,
                      },
                      onSuccess: (freshUserDetails) => {
                        if (freshUserDetails.phoneVerifiedUNIX) {
                          setPhoneVerificationStatus("succeeded");
                          setPhoneVerificationCode();
                          setPhoneCodeExpiryUNIX();

                          queryParams.delete('phone');
                          queryParams.delete('e');
                          queryParams.delete('c');
                          history.replace({
                            search: `?${queryParams.toString()}`,
                          });

                        }
                        else {
                          setPhoneVerificationErrorMessage("Something went wrong.");
                        }
                        setPhoneVerificationProcessing(false);
                      },
                      onError: (error) => {
                        console.error("handleUpdateUserDetails", error);
                        if (error?.message === "Invalid access code.") {
                          setEmailVerificationErrorMessage("Invalid secret code.");
                        }
                        else {
                          setEmailVerificationErrorMessage("Something went wrong.");
                        }
                        setEmailVerificationProcessing(false);
                      },
                    });
                  }}
                >
                  {I18n.get("Submit")}

                  {!phoneVerificationProcessing ? null : 
                    <Spinner marginLeft={8} flex="none" size={20} />
                  }

                </Button>
              </Pane>
              
              <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                disabled={phoneVerificationProcessing}
                type="button"
                onClick={() => {
                  setUpdatedUserDetails((prev) => ({
                    ...prev,
                    phone: userDetails.phone,
                    phoneDialCode: userDetails.phoneDialCode,
                  }));
                  setPhoneVerificationErrorMessage();
                  setPhoneVerificationCode();
                  setPhoneVerificationProcessing(false);
                  setPhoneVerificationStatus();
                }}
              >
                {I18n.get("Cancel")}
              </Button>

              </Pane>
            </Pane>
          </Pane>
        }

        {!phoneVerificationErrorMessage ? null :
          <Pane marginTop={12} alignItems="baseline" display="flex" flexWrap="wrap" >
            <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
            <Pane flex={1} flexBasis={242} maxWidth={390} >
              <Pane height={40} width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap" >
                  
                <Strong fontSize={14} color="#EC4C47" fontWeight={500} display="inline-flex" marginRight={20} >
                  {I18n.get(phoneVerificationErrorMessage)}
                </Strong> 

                <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                  disabled={false}
                  type="button"
                  onClick={() => {
                    setPhoneVerificationErrorMessage();
                    if (phoneVerificationStatus === "error") {
                      setPhoneVerificationStatus("pending");
                    }
                  }}
                >
                  {I18n.get("Try again")}
                </Button>

              </Pane>
            </Pane>
          </Pane>
        }

      </form>


      {/* <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" minHeight={40} >
        <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={is425PlusScreen ? 180 : "100%"} marginTop={is425PlusScreen ? 2 : 0} marginBottom={is425PlusScreen ? 0 : 4} >
          <Strong fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Language')}</Strong> 
        </Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <SelectLanguageDropdown fontColor="#425A70" fontSize={14} />
          </Pane>
        </Pane>
      </Pane> */}

      {/* <Pane marginBottom={10} alignItems="center" display="flex" flexWrap="wrap" minHeight={40} >
        <Pane textAlign={is425PlusScreen ? "right" : "left"} height="auto" flex={1} minWidth={140} maxWidth={is425PlusScreen ? 180 : "100%"} marginTop={is425PlusScreen ? 2 : 0} marginBottom={is425PlusScreen ? 0 : 4} >
          <Strong fontSize={14} lineHeight={"18px"} color="#425A70" fontWeight={500} display="inline-flex" marginRight={32} >{I18n.get('Currency')}</Strong> 
        </Pane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <PickCurrency fontColor="#425A70" fontSize={14} hideLabel={true} />
          </Pane>
        </Pane>
      </Pane> */}
      
      

      {/* <Pane marginTop={20} alignItems="baseline" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          {processing ?
            <Pane width="100%" maxWidth={380} paddingY={12} alignItems="center" >
              <Pane width="80%" height={16} marginLeft="auto" marginRight="auto" borderRadius={8} className="colourfulAnimation"></Pane>
            </Pane>
          :
            <Pane width="100%" maxWidth={380} alignItems="center" display="flex" flexWrap="wrap">

              <Pane flex="none" marginRight={36} >
                  <Button fontSize={16} paddingX={18} height={40} minWidth={100} justifyContent="center" className="button-blue"
                    disabled={processing || _.isEqual(userDetailsContactInformation, updatedUserDetails) || !updatedUserDetails.email || !updatedUserDetails.phone}
                    onClick={() => {
                      setProcessing(true);
                      handleUpdateUserDetails({
                        userDetailsToUpdate: {
                          ...updatedUserDetails,
                        },
                        onSuccess: (freshUserDetails) => {
                          setProcessing(false);
                        },
                        onError: (error) => {
                          setProcessing(false);
                        },
                      });
                    }}
                  >
                    {I18n.get("Save")}
                  </Button>
                </Pane>

              {_.isEqual(userDetailsContactInformation, updatedUserDetails) ? null :
                <Button fontSize={14} justifyContent="center" paddingX={12} height={30} minWidth={80}
                  disabled={false}
                  type="button"
                  onClick={() => {
                    setUpdatedUserDetails(_.cloneDeep(userDetailsContactInformation));
                  }}
                >
                  {I18n.get("Discard")}
                </Button>
              }

            </Pane>
          }
        </Pane>
      </Pane> */}


    </Fragment>
  );
}

const AccountFaceId = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountFaceId - ${countRef.current}`);
    return () => {
      console.log("AccountFaceId - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const handleUpdateUserDetails = useAuthUpdateUserDetails();
  

  const dispatch = useDispatch();
  // const localeState = useSelector(state => state.localeState);
  const userState = useSelector(state => state.userState);

  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);

  const [processing, setProcessing] = useState(false);

  const ResponsivePane = styled(Pane)`
    text-align: right;
    @media only screen and (max-width: ${425}px) {
      text-align: left;
    }
  `;

  if (userDetails.tags?.filter(tag => ["charity", "corporate", "retailer"].includes(tag)).length > 0) {
    return null;
  }

  return (
    <Fragment>

      <Pane width="100%" marginTop={20} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

      <Pane paddingTop={20} paddingBottom={10} alignItems="center" display="flex" flexWrap="wrap" >
        <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} ></ResponsivePane>
        <Pane flex={1} flexBasis={242} maxWidth={390} >
          <Pane maxWidth={380} >
            <Strong fontSize={16} color="#425A70" >{I18n.get('Account verification')}</Strong>
          </Pane>
        </Pane>
      </Pane>

      <Fragment>
        <Pane marginBottom={10} alignItems="baseline" display="flex" flexWrap="wrap" >
          <ResponsivePane flex={1} minWidth={140} maxWidth={180} marginBottom={4} marginTop={2} >
            <Strong fontSize={14} color="#425A70" display="inline-flex" marginRight={32} >{I18n.get('Status')}</Strong> 
          </ResponsivePane>
          <Pane flex={1} flexBasis={242} maxWidth={390} marginBottom={4} >
            <Pane maxWidth={380} >

              {userDetails.settings.faceLinked ?
                <Fragment>
                  <Pane >
                    <Strong fontSize={14} color="#283655" >{I18n.get('Verified')}</Strong>
                  </Pane>
                  <Button marginTop={12} fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                    disabled={processing}
                    onClick={() => {
                      // deactivate Face ID ???
                    }}
                  >
                    {I18n.get('Remove verification')}
                  </Button>
                  
                </Fragment>
              :
                <Fragment>
                  <Button fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                    disabled={processing}
                    onClick={() => {
                      dispatch(setDialogState({
                        componentName: "LinkFace",
                      }));
                    }}
                  >
                    {I18n.get('Set up Face ID')}
                  </Button>
                  <Pane marginTop={4} >
                    <Text fontSize={12} fontStyle="italic" color="#7B8B9A" >{`${I18n.get("Set up Face ID to use your balance to pay to our partner retailers.")}`}</Text>
                  </Pane>
                </Fragment>
              }
                
            </Pane>
          </Pane>
        </Pane>
      </Fragment>

    </Fragment> 
  );
}

const AccountPersonalDetails = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`AccountManagePayments - ${countRef.current}`);
    return () => {
      console.log("AccountManagePayments - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  const userState = useSelector(state => state.userState);
  const userDetails = useMemo(() => {
    return (userState.actAsUser || userState.user);
  }, [userState]);
  
  return (
    <Fragment>

      <AccountContactInformation />

      <AccountFaceId />
      
      {userDetails.tags?.filter(tag => ["charity", "corporate", "retailer",].includes(tag)).length === 0 && false ?
        <Pane marginTop={16} >
          <AccountGiftAidDeclaration />
        </Pane>
      : null }

      <AccountMatchFundingDeclaration />
      
    </Fragment>
  );

}


export default AccountPersonalDetails;