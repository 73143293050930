import { Fragment, cloneElement, useEffect, useState, useCallback, useRef, useMemo, useContext } from 'react';

import { ReactComponent as FDCameraFacePH } from '../images/fd_img_camera_face_ph.svg';// https://react-svgr.com/
import { ReactComponent as FDFBLoopDonate } from '../assets/images/feedback_loop_donate.svg';
import { ReactComponent as FDFBLoopArrows } from '../assets/images/feedback_loop_arrows.svg';
import { ReactComponent as FDFBLoopDownArrow } from '../assets/images/feedback_loop_down_arrow.svg';
import { ReactComponent as FDFBLoopSeeImpact } from '../assets/images/feedback_loop_see_impact.svg';
import { ReactComponent as FaceDonateImage3 } from '../images/facedonate_img_3.svg';
import { ReactComponent as FdImgFood } from '../images/fd_img_food.svg';
import { ReactComponent as FdImgBank } from '../images/fd_img_bank.svg';
import { ReactComponent as FdImgPrivacy } from '../images/fd_img_privacy.svg';
import { ReactComponent as FdIngCap } from '../images/fd_img_cap.svg';
import { ReactComponent as FdImgMission } from '../images/fd_img_mission_main.svg';
import { ReactComponent as FaceDonateImage4 } from '../images/facedonate_img_4.svg';
import { ReactComponent as FaceDonateImage5 } from '../images/facedonate_img_5.svg';
import { ReactComponent as FaceDonateImage6 } from '../images/facedonate_img_6.svg';
import { ReactComponent as FaceDonateImage7 } from '../images/facedonate_img_7.svg';

import { ReactComponent as Fd96 } from '../images/fd96.svg';


import TwitterIcon from '../assets/icons/TwitterIcon';
import FacebookIcon from '../assets/icons/FacebookIcon';
import InstagramIcon from '../assets/icons/InstagramIcon';
import FacedonateIcon from '../assets/icons/FacedonateIcon';
import LinkedinIcon from '../assets/icons/LinkedinIcon';
import YoutubeIcon from '../assets/icons/YoutubeIcon';

import ProfileIcon from '../assets/icons/ProfileIcon';


// import { ReactComponent as FaceDonateScalesImage } from '../assets/images/FaceDonateScalesImage.png';
import FaceDonateScalesImage from '../assets/images/FaceDonateScalesImage.png';
import FaceDonateSdgsEmblemHImage from '../assets/images/E_SDG_logo_UN_emblem_horizontal_trans_WEB.png';


import { Link, generatePath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Heading, Text, majorScale, SegmentedControl, UnorderedList, minorScale, NotificationsUpdatedIcon, MobilePhoneIcon, GlobeIcon, MoreIcon, ArrowLeftIcon, ShareIcon, MenuIcon, ArrowRightIcon, TreeIcon, GlobeNetworkIcon, ChevronDownIcon, DashboardIcon, AutomaticUpdatesIcon, ChartIcon, ShopIcon, ShieldIcon, HeatmapIcon, HeartIcon, TickIcon, CrossIcon, TextInput, FullCircleIcon, SwapVerticalIcon, InfoSignIcon, SearchIcon, ChevronRightIcon, Tooltip, Strong, Popover, Menu, Position, Pill, ListItem, Dialog, Spinner, TextInputField, Paragraph, Icon, Button, Checkbox, IconButton, Avatar, KeyDeleteIcon, Pane, toaster} from 'evergreen-ui';
import { pathToRegexp, compile as pathToRegexpCompile } from 'path-to-regexp';

import { RemoveScroll } from 'react-remove-scroll';
import { zeroRightClassName, fullWidthClassName, noScrollbarsClassName } from 'react-remove-scroll-bar';

import Gradient from 'rgt';

import GaugeChart from 'react-gauge-chart';

import Typewriter from 'typewriter-effect';

import { G2, TinyArea, RingProgress, Column, Bar, Histogram, Bullet, RadialBar, DualAxes, Area, CirclePacking, Treemap } from '@ant-design/plots';

import ResponsiveEmbed from 'react-responsive-embed';
import ReactPlayer from 'react-player/youtube';

import { useMediaQuery } from 'react-responsive';

import { I18n } from 'aws-amplify';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import numeral from 'numeral'; // http://numeraljs.com/

import TakeFacePicturePic from '../assets/images/TakeFacePicturePic';

import Footer from '../components/Footer';
import AspectRatio from '../components/AspectRatio';
import DynamicIconButton from '../components/DynamicIconButton';

import MissionSection from '../components/MissionSection';
import HowWorksSection from '../components/HowWorksSection';

import AchievementsSection from '../components/AchievementsSection';
import MainExplainerSection from '../components/MainExplainerSection';
import GalleryOfGoodCompany from '../components/GalleryOfGoodCompany';
import GalleryOfBeneficiariesTestimonials from '../components/GalleryOfBeneficiariesTestimonials';

import { useSelector, useDispatch, batch } from 'react-redux';
import { setProfileDetails, updateProfileState, setDialogState, updateDialogState, setTransactionDetails, setBasicStats } from '../services/actions';

import CountUp, { useCountUp } from 'react-countup';

import { currencies } from '../services/localeDictionary';


import { NumericFormat } from 'react-number-format';

import PickLanguage from '../components/PickLanguage';


const GoodCompanySection = ({...rest}) => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`GoodCompanySection - ${countRef.current}`);
    return () => {
      console.log("GoodCompanySection - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  return (
    <Fragment >

      <Pane width="100%" textAlign="center" {...rest} >
        <Text is={"h3"} fontSize={18} lineHeight={"inherit"} marginY={20} color="#7B8B9A">{I18n.get("You'll be in great company")}</Text>
      </Pane>

      <Pane marginTop={36}>
        <GalleryOfGoodCompany />
      </Pane>

    </Fragment>
  );
}

const BeneficiariesTestimonialsSection = ({...rest}) => {
  
  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`BeneficiariesTestimonialsSection - ${countRef.current}`);
    return () => {
      console.log("BeneficiariesTestimonialsSection - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  return (
    <Pane {...rest} >

      {/* <Pane width="100%" textAlign="center" {...rest} >
        <Text is={"h3"} fontSize={18} lineHeight={"inherit"} marginY={20} color="#7B8B9A">{I18n.get("You'll be in great company")}</Text>
      </Pane> */}

      <Pane >
        <GalleryOfBeneficiariesTestimonials />
      </Pane>

    </Pane>
  );
}


const FooterNavButton = ({
  disabled=false,
  children,
  to,
  isSoon=false,
  ...rest
}) => {

  return (
    <Fragment>

      {/* {cloneElement(children, {
        to: to,
      })} */}

      <Pane justifyContent="left" display="flex" paddingY={1} >
        <Button
          style={{
            "color": "#283655",
            "fontWeight": "400",
            "height": "auto",
            "lineHeight": "inherit"
          }}
          appearance="minimal"
          fontSize={"1rem"}
          padding={4}
          // height={32}
          justifyContent="center"
          // className='button-blue'
          is={disabled ? Pane : Link}
          to={to}
        >
          {children}
        </Button>

        {!isSoon ? null :
          <Pane width={"fit-content"} height={"1.1rem"} background="#283655" borderRadius={"0.625rem"} padding={"0.25rem"} textAlign="center" alignItems="center" display="flex" >
            <Text fontSize={"0.625rem"} color="#FFFFFF" >{I18n.get("Coming")}</Text>
          </Pane>
        }

      </Pane>

      

    </Fragment>
  );
}

const DemoTinyArea = () => {
  const data = [
    64, 17, 38, 87, 9, 97, 50, 75, 63, 30, 25, 42, 51, 67,
  ];
  const config = {
    height: 60,
    width: "100%",
    autoFit: true,
    data,
    smooth: true,
  };
  return <TinyArea {...config} />;
};

const DemoRingProgress = () => {
  const config = {
    height: 80,
    width: 80,
    autoFit: true,
    percent: 0.85,
    color: ['#5B8FF9', '#E8EDF3'],
    innerRadius: 0.75,
    // radius: 0.98,
    statistic: {
      // title: false,
      content: false,
      title: {
        style: {
          color: '#363636',
          fontSize: '12px',
          lineHeight: '14px',
        },
        formatter: (e) => {
          return (`${Math.round((e.percent + Number.EPSILON) * 100)}%`);
        },
      },
    },
  };
  return <RingProgress {...config} />;
};

const DemoColumn = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      { "name": "X", "month": "Jan.", "value": 70, "type": "Food" },
      // { "name": "理科", "month": "Jan.", "value": 67, "type": "数学" },
      { "name": "X", "month": "Jan.", "value": 90.5, "type": "Health" },
      { "name": "X", "month": "Jan.", "value": 62.4, "type": "Education" },
      // { "name": "理科", "month": "Jan.", "value": 72.4, "type": "线性代数" },

      { "name": "X", "month": "Feb.", "value": 82.5, "type": "Food" },
      // { "name": "理科", "month": "Feb.", "value": 65.5, "type": "数学" },
      { "name": "X", "month": "Feb.", "value": 79, "type": "Health" },
      { "name": "X", "month": "Feb.", "value": 79, "type": "Home" },
      { "name": "X", "month": "Feb.", "value": 81.5, "type": "Education" },
      // { "name": "理科", "month": "Feb.", "value": 82.4, "type": "线性代数" },

      { "name": "X", "month": "Mar.", "value": 77.5, "type": "Food" },
      // { "name": "理科", "month": "Mar.", "value": 65.5, "type": "数学" },
      { "name": "X", "month": "Mar.", "value": 72, "type": "Health" },
      { "name": "X", "month": "Mar.", "value": 85.4, "type": "Education" },
      // { "name": "理科", "month": "Mar.", "value": 82.5, "type": "线性代数" }
    ]);
    // asyncFetch();
  }, []);

  const pattern = ({ name }) => {
    return name === 'X'
      ? {
          // type: 'line',
          cfg: {
            lineWidth: 6,
            spacing: 5,
          },
        }
      : {
          // type: 'dot',
          cfg: {
            size: 15,
          },
        };
  };

  const config = {
    data,
    xField: 'month',
    yField: 'value',
    isGroup: true,
    isStack: true,
    seriesField: 'type',
    groupField: 'name',
    rawFields: ['type', 'name'],
    columnStyle: {
      radius: 6,
    },
    pattern,
    legend: {
      layout: 'horizontal',
      position: 'top'
    }
  };

  return <Column {...config} />;
};

const DemoColumn2 = () => {
  const data = [
    {
      type: '1',
      value: 1,
    },
    {
      type: '2',
      value: 1,
    },
    {
      type: '3',
      value: 1,
    },
    {
      type: '4',
      value: 0.19,
    },
    {
      type: '5',
      value: 0.22,
    },
    {
      type: '6',
      value: 0.05,
    },
    {
      type: '7',
      value: 0.01,
    },
    {
      type: '8',
      value: 0.015,
    },
    {
      type: '9',
      value: 1,
    },
    {
      type: '10',
      value: 1,
    },
    {
      type: '11',
      value: 1,
    },
    {
      type: '12',
      value: 0.19,
    },
    {
      type: '13',
      value: 0.22,
    },
    {
      type: '14',
      value: 0.05,
    },
    {
      type: '15',
      value: 0.19,
    },
    {
      type: '16',
      value: 0.22,
    },
    {
      type: '17',
      value: 0.05,
    },
  ];
  const paletteSemanticRed = '#F4664A';
  const brandColor = '#5B8FF9';
  const config = {
    data,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    color: ({ type }) => {
      if (type === '7' || type === '8') {
        return paletteSemanticRed;
      }

      return brandColor;
    },
    columnStyle: {
      radius: 6,
    },
    label: {
      content: (originData) => {
        const val = parseFloat(originData.value);

        if (val < 0.05) {
          return (val * 100).toFixed(1) + '%';
        }
      },
      offset: 10,
    },
    legend: false,
    yAxis: {
      label: null,
      // label: {
      //   autoHide: true,
      //   autoRotate: false,
      // },
    },
    xAxis: {
      label: null,
      // label: {
      //   autoHide: true,
      //   autoRotate: false,
      // },
    },
  };
  return <Column {...config} />;
};

const DemoBar = () => {
  const data = [
    {
      country: 'Asia',
      year: '1750',
      value: 502,
    },
    {
      country: 'Asia',
      year: '1800',
      value: 635,
    },
    {
      country: 'Asia',
      year: '1850',
      value: 809,
    },
    {
      country: 'Asia',
      year: '1900',
      value: 947,
    },
    {
      country: 'Asia',
      year: '1950',
      value: 1402,
    },
    {
      country: 'Asia',
      year: '1999',
      value: 3634,
    },
    {
      country: 'Asia',
      year: '2050',
      value: 5268,
    },
    {
      country: 'Africa',
      year: '1750',
      value: 106,
    },
    {
      country: 'Africa',
      year: '1800',
      value: 107,
    },
    {
      country: 'Africa',
      year: '1850',
      value: 111,
    },
    {
      country: 'Africa',
      year: '1900',
      value: 133,
    },
    {
      country: 'Africa',
      year: '1950',
      value: 221,
    },
    {
      country: 'Africa',
      year: '1999',
      value: 767,
    },
    {
      country: 'Africa',
      year: '2050',
      value: 1766,
    },
    {
      country: 'Europe',
      year: '1750',
      value: 163,
    },
    {
      country: 'Europe',
      year: '1800',
      value: 203,
    },
    {
      country: 'Europe',
      year: '1850',
      value: 276,
    },
    {
      country: 'Europe',
      year: '1900',
      value: 408,
    },
    {
      country: 'Europe',
      year: '1950',
      value: 547,
    },
    {
      country: 'Europe',
      year: '1999',
      value: 729,
    },
    {
      country: 'Europe',
      year: '2050',
      value: 628,
    },
  ];
  const config = {
    data,
    xField: 'value',
    yField: 'year',
    seriesField: 'country',
    isPercent: true,
    isStack: true,

    /** 自定义颜色 */
    // color: ['#2582a1', '#f88c24', '#c52125', '#87f4d0'],
    label: {
      position: 'middle',
      content: (item) => {
        return item.value.toFixed(2);
      },
      style: {
        fill: '#fff',
      },
    },
  };
  return <Bar {...config} />;
};

const DemoBar2 = () => {

  const data = [
    {
      type: 'Donated',
      yField: 'Source of funds',
      value: 3138,
    },
    {
      type: 'Raised',
      yField: 'Source of funds',
      value: 8263,
    },
  ];

  const config = {
    data,
    xField: 'value',
    yField: 'yField',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
    autoFit: true,

    /** 自定义颜色 */
    color: ['#47B881', '#1070CA'], // '#c52125', '#87f4d0'
    // label: {
    //   position: 'middle',
    //   content: (item) => {
    //     let v = Math.round((item.value + Number.EPSILON) * 100);
    //     // let v = item.value.toFixed(2) * 100;
    //     return (`${v}%`);
    //   },
    //   style: {
    //     fill: '#fff',
    //   },
    // },
    yAxis: {
      label: null,
      // label: {
      //   autoHide: true,
      //   autoRotate: false,
      // },
    },
    xAxis: {
      label: null,
      // label: {
      //   autoHide: true,
      //   autoRotate: false,
      // },
    },
    barStyle: {
      radius: 6,
    },
    tooltip: false,
    legend: {
      // custom: true,
      position: 'top',
      flipPage: false,
      itemSpacing: 10,
    },
  };

  return <Bar {...config} />;

};

const DemoBar3 = () => {
  const data = [
    {
      type: '🥕',
      yField: 'Typical use of funds',
      value: 5.8,
    },
    {
      type: '🥩',
      yField: 'Typical use of funds',
      value: 3.75,
    },
    {
      type: '🥐',
      yField: 'Typical use of funds',
      value: 1.1,
    },
    {
      type: '🥛',
      yField: 'Typical use of funds',
      value: 6,
    },
    {
      type: '🥫',
      yField: 'Typical use of funds',
      value: 4,
    },
    {
      type: '🧴',
      yField: 'Typical use of funds',
      value: 1,
    },
  ];
  G2.registerInteraction('legend',
    // {
    //   start: [
    //     {
    //       trigger: 'interval:mouseenter',
    //       action: 'element-link-by-color:link',
    //     },
    //   ],
    //   end: [
    //     {
    //       trigger: 'interval:mouseleave',
    //       action: 'element-link-by-color:unlink',
    //     },
    //   ],
    // }
  );
  const config = {
    data,
    xField: 'value',
    yField: 'yField',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
    autoFit: true,
    
    color: ['#47B881', '#EC4C47', '#F8BA00', '#73FDEA', '#00A2FF', '#FF8DC6'],
    // label: {
    //   position: 'middle',
    //   content: (item) => {
    //     let v = Math.round((item.value + Number.EPSILON) * 100);
    //     // let v = item.value.toFixed(2) * 100;
    //     return (`${v}%`);
    //   },
    //   style: {
    //     fill: '#fff',
    //   },
    // },
    yAxis: {
      label: null,
      // label: {
      //   autoHide: true,
      //   autoRotate: false,
      // },
    },
    xAxis: {
      label: null,
      // label: {
      //   autoHide: true,
      //   autoRotate: false,
      // },
    },
    barStyle: {
      radius: 6,
    },
    legend: {
      // custom: true,
      position: 'top',
      flipPage: false,
      itemSpacing: 2,
    },
    // legend: {
    //   custom: true,
    //   position: 'bottom',
    //   items: [
    //     {
    //       value: '差',
    //       name: '差',
    //       marker: {
    //         symbol: 'square',
    //         style: {
    //           fill: '#FFbcb8',
    //           r: 5,
    //         },
    //       },
    //     },
    //     {
    //       value: '良',
    //       name: '良',
    //       marker: {
    //         symbol: 'square',
    //         style: {
    //           fill: '#FFe0b0',
    //           r: 5,
    //         },
    //       },
    //     },
    //     {
    //       value: '优',
    //       name: '优',
    //       marker: {
    //         symbol: 'square',
    //         style: {
    //           fill: '#bfeec8',
    //           r: 5,
    //         },
    //       },
    //     },
    //     {
    //       value: '第一季度',
    //       name: '第一季度',
    //       marker: {
    //         symbol: 'square',
    //         style: {
    //           fill: '#5B8FF9',
    //           r: 5,
    //         },
    //       },
    //     },
    //     {
    //       value: '第二季度',
    //       name: '第二季度',
    //       marker: {
    //         symbol: 'square',
    //         style: {
    //           fill: ' #61DDAA',
    //           r: 5,
    //         },
    //       },
    //     },
    //     {
    //       value: '目标值',
    //       name: '目标值',
    //       marker: {
    //         symbol: 'line',
    //         style: {
    //           stroke: '#39a3f4',
    //           r: 5,
    //         },
    //       },
    //     },
    //   ],
    // },
    tooltip: false,
    interactions: [
      {
        type: 'element-highlight',
      },
      {
        type: 'element-legend-active',
      },
      {
        type: 'element-active',
      },
      {
        type: 'legend-highlight',
        enabled: false,
      },
      {
        type: 'legend-filter',
        enabled: false,
      },
    ],
  };
  return <Bar {...config} />;
};

const DemoHistogram = () => {
  const data = [
    {
      value: 5,
    },
    {
      value: 5,
    },
    {
      value: 15,
    },
    {
      value: 15,
    },
    {
      value: 15,
    },
    {
      value: 20,
    },
    {
      value: 20,
    },
    {
      value: 20,
    },
    {
      value: 20,
    },
    {
      value: 20,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 30,
    },
    {
      value: 40,
    },
    {
      value: 40,
    },
    {
      value: 40,
    },
    {
      value: 40,
    },
    {
      value: 40,
    },
    {
      value: 50,
    },
    {
      value: 50,
    },
    {
      value: 50,
    },
    {
      value: 50,
    },
    {
      value: 50,
    },
    {
      value: 50,
    },
    {
      value: 50,
    },
    {
      value: 60,
    },
    {
      value: 60,
    },
    {
      value: 60,
    },
    {
      value: 60,
    },
    {
      value: 60,
    },
    {
      value: 60,
    },
    {
      value: 65,
    },
    {
      value: 65,
    },
    {
      value: 80,
    },
    {
      value: 80,
    },
    {
      value: 80,
    },
    {
      value: 90,
    },
    {
      value: 90,
    },
    {
      value: 100,
    },
    {
      value: 100,
    },
    {
      value: 100,
    },
  ];
  const config = {
    data,
    yAxis: {
      label: null,
      // label: {
      //   autoHide: true,
      //   autoRotate: false,
      // },
    },
    xAxis: {
      label: {
          formatter: (text, item, index) => {
          // console.log("text", text);
          // console.log("item", item);
          return (`£${text}`);
        }
      }
    },
    // autoFit: true,
    binField: 'value',
    binWidth: 20,
    // binNumber: 6,
    columnStyle: {
      radius: 6,
    },
    tooltip: {
      showMarkers: false,
      position: 'top',
    },
    interactions: [
      {
        type: 'element-highlight',
      },
    ],

    /** range x，count y */
    meta: {
      range: {
        // min: 0,
        tickInterval: 5,
        nice: true,
      },
      count: {
        // max: 100,
        nice: true,
      },
    },
  };
  return <Histogram {...config} />;
};

const DemoBullet = () => {
  const data = [
    {
      title: '满意度',
      ranges: [100],
      measures: [80],
      target: 85,
    },
  ];
  const config = {
    data,
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    xField: 'title',
    color: {
      range: '#f0efff',
      measure: '#5B8FF9',
      target: '#3D76DD',
    },
    xAxis: {
      line: null,
    },
    yAxis: {
      tickMethod: ({ max }) => {
        const interval = Math.ceil(max / 5); // 自定义刻度 ticks

        return [0, interval, interval * 2, interval * 3, interval * 4, max];
      },
    },
    // 自定义 legend
    legend: {
      custom: true,
      position: 'bottom',
      items: [
        {
          value: '实际值',
          name: '实际值',
          marker: {
            symbol: 'square',
            style: {
              fill: '#5B8FF9',
              r: 5,
            },
          },
        },
        {
          value: '目标值',
          name: '目标值',
          marker: {
            symbol: 'line',
            style: {
              stroke: '#3D76DD',
              r: 5,
            },
          },
        },
      ],
    },
  };
  return <Bullet {...config} />;
};

const DemoRadialBar = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        "year": "Instant Neighbour",
        "value": 7,
        "type": "Lon"
      },
      {
        "year": "Nickel Support",
        "value": 9,
        "type": "Lon"
      },
      {
        "year": "Hackney Foodbank",
        "value": 13,
        "type": "Lon"
      },
      {
        "year": "Instant Neighbour",
        "value": 7,
        "type": "Bor"
      },
      {
        "year": "Nickel Support",
        "value": 9,
        "type": "Bor"
      },
      {
        "year": "Hackney Foodbank",
        "value": 13,
        "type": "Bor"
      }
    ]);
  }, []);
  
  const config = {
    data,
    xField: 'year',
    yField: 'value',
    colorField: 'type',
    isStack: true,
    maxAngle: 270,
  };

  return <RadialBar {...config} />;
};

const DemoCirclePacking = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData({
      "name": "root",
      "children": [
        { "name": "1", "value": 1046790, "color": "#E5243B" },
        { "name": "2", "value": 1039358, "color": "#DDA83A" },
        { "name": "3", "value": 461880, "color": "#4C9F38" },
        { "name": "4", "value": 308136, "color": "#C5192D" },
        { "name": "5", "value": 270578, "color": "#FF3A21" },
        { "name": "6", "value": 226334, "color": "#26BDE2" },
        { "name": "7", "value": 197342, "color": "#FCC30B" },
        { "name": "8", "value": 189739, "color": "#A21942" },
        { "name": "9", "value": 175272, "color": "#FD6925" },
        { "name": "10", "value": 150621, "color": "#DD1367" },
        { "name": "11", "value": 138255, "color": "#FD9D24" },
        { "name": "12", "value": 121216, "color": "#BF8B2E" },
        { "name": "13", "value": 119912, "color": "#3F7E44" },
        { "name": "14", "value": 102488, "color": "#0A97D9" },
        { "name": "15", "value": 90157, "color": "#56C02B" },
        { "name": "16", "value": 59307, "color": "#00689D" },
        { "name": "17", "value": 10000, "color": "#19486A" },
      ]
    });
  }, []);
  
  const config = {
    autoFit: true,
    padding: 0,
    data,
    sizeField: 'r',
    // color: 'rgb(252, 253, 191)-rgb(231, 82, 99)-rgb(183, 55, 121)',
    // color: ["#FAFBFF", '#D23A42', '#D5AA4F', '#629D47'],
    // rawFields: ['color'],
    colorField: 'color',
    color: (e) => {
      // console.log("e", e);
      const backgroundColor = "#FAFBFF";
      return(e.color || backgroundColor);
    },
    
    // 自定义 label 样式
    label: {
      formatter: ({ name }) => {
        return name !== 'root' ? name : '';
      },
      // 偏移
      offsetY: 15,
      style: {
        fontSize: 12,
        textAlign: 'center',
        fill: 'rgba(0,0,0,0.65)',
      },
    },
    legend: false,
    // annotations: [
    //   {
    //     type: 'text',
    //     position: ['50%', '50%'],
    //     content: 'Music',
    //     style: {
    //       textAlign: 'center',
    //       fontSize: 24,
    //     },
    //   },
    // ],
  };

  return <CirclePacking {...config} />;
};

const DemoTreemap = () => {
  const data = {
      "name": "root",
      "children": [
        { "name": "1", "value": 100, "color": "#E5243B" },
        { "name": "2", "value": 100, "color": "#DDA83A" },
        { "name": "3", "value": 100, "color": "#4C9F38" },
        { "name": "4", "value": 100, "color": "#C5192D" },
        { "name": "5", "value": 100, "color": "#FF3A21" },
        { "name": "6", "value": 100, "color": "#26BDE2" },
        { "name": "7", "value": 100, "color": "#FCC30B" },
        { "name": "8", "value": 100, "color": "#A21942" },
        { "name": "9", "value": 100, "color": "#FD6925" },
        { "name": "10", "value": 100, "color": "#DD1367" },
        { "name": "11", "value": 100, "color": "#FD9D24" },
        { "name": "12", "value": 100, "color": "#BF8B2E" },
        { "name": "13", "value": 100, "color": "#3F7E44" },
        { "name": "14", "value": 100, "color": "#0A97D9" },
        { "name": "15", "value": 100, "color": "#56C02B" },
        { "name": "16", "value": 100, "color": "#00689D" },
        { "name": "17", "value": 100, "color": "#19486A" },
      ]
    };
  const config = {
    data,
    colorField: 'color',
    color: (e) => {
      // console.log("e", e);
      const backGroundColor = "#FAFBFF";
      return(e.color || backGroundColor);
    },
    legend: false,
    label: {
      formatter: ({ name }) => {
        return name !== 'root' ? name : '';
      },
      // offsetY: 15,
      style: {
        fontSize: 12,
        textAlign: 'center',
        // fill: 'rgba(0,0,0,0.65)',
      },
    },
  };
  return <Treemap {...config} />;
};

const DemoDualAxes = () => {
  const uvBillData = [
    {
      time: 'Jan.',
      value: 270,
      type: 'Your contributions',
    },
    {
      time: 'Feb.',
      value: 320,
      type: 'Your contributions',
    },
    {
      time: 'Mar.',
      value: 450,
      type: 'Your contributions',
    },
    {
      time: 'Jan.',
      value: 220,
      type: 'Average',
    },
    {
      time: 'Feb.',
      value: 300,
      type: 'Average',
    },
    {
      time: 'Mar.',
      value: 360,
      type: 'Average',
    },
  ];
  const transformData = [
    // {
    //   time: 'Jan.',
    //   count: 55,
    //   name: 'Average score',
    // },
    // {
    //   time: 'Feb.',
    //   count: 57,
    //   name: 'Average score',
    // },
    // {
    //   time: 'Mar.',
    //   count: 60,
    //   name: 'Average score',
    // },
    // {
    //   time: 'Jan.',
    //   count: 67,
    //   name: 'Your score',
    // },
    // {
    //   time: 'Feb.',
    //   count: 75,
    //   name: 'Your score',
    // },
    // {
    //   time: 'Mar.',
    //   count: 73,
    //   name: 'Your score',
    // },
  ];
  const config = {
    data: [uvBillData, transformData],
    xField: 'time',
    yField: ['value', 'count'],
    
    tooltip: {
      showMarkers: false,
      position: 'top',
    },
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'type',
        lineStyle: {
          lineWidth: 3,
          lineDash: [5, 5],
          // stroke: '#7B8B9A',
        },
        
        smooth: true,
        // colorField: 'color',
        // color: (e) => {
        //   // console.log("e", e);
        //   return(e.color || '#7B8B9A');
        // },
        color: ["#5B8FF9", '#7B8B9A',],
      },
      {
        geometry: 'line',
        seriesField: 'name',
        point: {},
      },
    ],
  };
  return <DualAxes {...config} />;
};

const DemoArea = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        "date": "Jan.",
        "value": 686,
        "category": "Match funding"
      },
      {
        "date": "Jan.",
        "value": 857,
        "category": "Raised"
      },
      {
        "date": "Jan.",
        "value": 500,
        "category": "Direct"
      },
      
      {
        "date": "Feb.",
        "value": 1470,
        "category": "Match funding"
      },
      {
        "date": "Feb.",
        "value": 1634,
        "category": "Raised"
      },
      {
        "date": "Feb.",
        "value": 500,
        "category": "Direct"
      },
      {
        "date": "Mar.",
        "value": 1687,
        "category": "Match funding"
      },
      {
        "date": "Mar.",
        "value": 1984,
        "category": "Raised"
      },
      
      {
        "date": "Mar.",
        "value": 500,
        "category": "Direct"
      },
      {
        "date": "Apr.",
        "value": 1687,
        "category": "Match funding"
      },
      {
        "date": "Apr.",
        "value": 1984,
        "category": "Raised"
      },
      
      {
        "date": "Apr.",
        "value": 500,
        "category": "Direct"
      },
      
    ]);
  }, []);

  
  
  const config = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    color: ['#6897a7', '#8bc0d6', '#60d7a7', '#dedede', '#fedca9', '#fab36f', '#d96d6f'],
    smooth: true,
    autoFit: true,
    xAxis: {
      // type: 'time',
      // mask: 'MMM.',
      // nice: true,
    },
    yAxis: {
      label: null,
      // label: {
      //   // 数值格式化为千分位
      //   formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `£${s},`),
      // },
    },
    legend: {
      position: 'top',
    },
  };

  return <Area {...config} />;
};

const DashboardMobileView = () => {

  return (

    <Fragment>

      <Pane>
        <Pane
          flex={1} 
          // background="#FAFBFF"
          // background="tint1"
          marginLeft="auto" marginRight="auto"
          // borderTopLeftRadius={10}
          // borderTopRightRadius={10}
          // borderBottomLeftRadius={10}
          // borderBottomRightRadius={10}
          
        >

          <Pane paddingTop={12} marginBottom={12} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
            <Strong fontWeight={700} lineHeight={"inherit"} fontSize={16} marginY={0} color="#283655">
              {I18n.get('Impact dashboard')}
            </Strong>
          </Pane>

          <Pane
            // width="220px"
            marginLeft="auto" marginRight="auto" >

            <GaugeChart id="gauge-chart-X"
              nrOfLevels={10}
              // arcsLength={[0.3, 0.5, 0.2]}
              colors={['#EC4C47', '#F5CD19', '#47B881']} // colors={['#5BE12C', '#F5CD19', '#EA4228']}
              marginInPercent={0.02}
              cornerRadius={5}
              percent={0.73}
              arcWidth={0.25}
              arcPadding={0.03}
              hideText={true}
              needleBaseColor="#283655"
              needleColor="#283655"
              style={{
                "width": "100%"
              }}
            />

            <Pane marginTop={4} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
              <Strong fontWeight={700} lineHeight={"inherit"} fontSize={20} marginY={0} color="#283655">
                {I18n.get('73')}
              </Strong>
              <Text fontSize={12} color="#7B8B9A" lineHeight={"inherit"} marginY={0} >
                {I18n.get('/100')}
              </Text>
            </Pane>
            <Pane paddingTop={2} textAlign="center" >
              <Text fontSize={12} color="#7B8B9A" >{I18n.get('Impact score')}</Text>
            </Pane>
          
          </Pane>

          {/* <Pane paddingTop={12} display={"flex"} flexWrap={"wrap"} gap={10} >
            <Pane
              // key={key}
              // display={"flex"}
              textAlign="center"
              flex={"1"}
              // width={32}
              minWidth={70}
              paddingX={8}
              justifyContent="center"
            >
        
              <Pane flex="none" >
                <Strong display="contents" flex="none" fontSize={20} color="#283655">{"12"}</Strong>
              </Pane>
              <Pane paddingTop={2} >
                <Text fontSize={14} color="#7B8B9A" >{I18n.get('initiatives')}</Text>
              </Pane>
        
            </Pane>
            <Pane
              // key={key}
              // display={"flex"}
              textAlign="center"
              flex={"1"}
              // width={32}
              minWidth={70}
              paddingX={8}
              justifyContent="center"
            >
        
              <Pane flex="none" >
                <Strong display="contents" flex="none" fontSize={20} color="#283655">{"2.6x"}</Strong>
              </Pane>
              <Pane paddingTop={2} >
                <Text fontSize={14} color="#7B8B9A" >{I18n.get("engagement")}</Text>
              </Pane>
        
            </Pane>
          </Pane> */}

          <Pane paddingTop={8} display={"flex"} flexWrap={"wrap"} gap={10} >
            <Pane
              // key={key}
              // display={"flex"}
              textAlign="center"
              flex={"1"}
              // width={32}
              minWidth={70}
              paddingX={8}
              justifyContent="center"
            >
        
              <Pane flex="none" >
                <Strong display="contents" flex="none" fontSize={16} color="#283655">{"£3,138"}</Strong>
              </Pane>
              <Pane paddingTop={0} >
                <Text fontSize={12} color="#7B8B9A" >{I18n.get('donated')}</Text>
              </Pane>
        
            </Pane>
            <Pane
              // key={key}
              // display={"flex"}
              textAlign="center"
              flex={"1"}
              // width={32}
              minWidth={70}
              paddingX={8}
              justifyContent="center"
            >
        
              <Pane flex="none" >
                <Strong display="contents" flex="none" fontSize={16} color="#283655">{"£8,263"}</Strong>
              </Pane>
              <Pane paddingTop={0} >
                <Text fontSize={12} color="#7B8B9A" >{I18n.get("raised")}</Text>
              </Pane>
        
            </Pane>
          </Pane>

          <Pane paddingTop={8} display={"flex"} flexWrap={"wrap"} gap={10} >
            <Pane
              // key={key}
              // display={"flex"}
              textAlign="center"
              flex={"1"}
              // width={32}
              minWidth={70}
              paddingX={8}
              justifyContent="center"
            >
        
              <Pane flex="none" >
                <Strong display="contents" flex="none" fontSize={16} color="#283655">{"247"}</Strong>
              </Pane>
              <Pane paddingTop={0} >
                <Text fontSize={12} color="#7B8B9A" >{I18n.get('adult days')}</Text>
              </Pane>
        
            </Pane>
            <Pane
              // key={key}
              // display={"flex"}
              textAlign="center"
              flex={"1"}
              // width={32}
              minWidth={70}
              paddingX={8}
              justifyContent="center"
            >
        
              <Pane flex="none" >
                <Strong display="contents" flex="none" fontSize={16} color="#283655">{"458"}</Strong>
              </Pane>
              <Pane paddingTop={0} >
                <Text fontSize={12} color="#7B8B9A" >{I18n.get("child days")}</Text>
              </Pane>
        
            </Pane>
          </Pane>

          <Pane paddingTop={8} >
            <DemoTinyArea />
          </Pane>

          {/* <Pane paddingY={12} display={"flex"} flexWrap={"wrap"} >
            
            <Button fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
              disabled={false}
              onClick={() => {
                
              }}
            >
              {I18n.get('View all transactions')}
            </Button>
          </Pane> */}


        </Pane>
      </Pane>

    </Fragment>

  );
}

const Home = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Home - ${countRef.current}`);
    return () => {
      console.log("Home - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();
  let history = useHistory();

  // console.log("Home match", match);

  const dispatch = useDispatch();
  // const userState = useSelector(state => state.userState);
  // const localeState = useSelector(state => state.localeState);

  const is426PlusScreen = useMediaQuery({ query: '(min-width: 426px)' });


  let tForDonors = 'For donors'
  let tForProviders = 'For providers'
  
  let tTakeAPortrait = "Take a picture";
  let tTakeAPortraitText = "Take a picture of the donation recipient - just one face image of a person, whom you wish to help.";
  let tTakeAPortraitTextFP = "No need to register to become a provider. Just take a picture of the person, who asks you to help, and see know how much they can spend in your shop.";
  let tKnowWhom = "Know whom you help";
  let tKnowWhomText = "See the person's name and their current balance. Be confident they need you. Trust you actually help.";
  let tNoCash = "Give no cash in hand";
  let tNoCashText = "Enter the amount you wish to donate. Make a contactless payment by using a bank card - 100% secure, safe and transparent. We will allocate your donation directly to their account.";
  let tKnowDoGood = "Know you do good";
  let tKnowDoGoodText = "Your donation will only pay for good things - donations may only be redeemed in person and be spent on good things.";
  let tKnowDoGoodTextTT = "Our providers of goods and services will receive funds from us, only if and when they demonstrate and prove what they provided was good.";

  let tHelpWithGS = "Provide";
  let tHelpWithGSTT = "Provide";
  let tHelpWithGSText = "Provide the required goods or service. Take a picture of your printed receipt for the ‘proof of good’. Insert your email, provide!";
  
  let tReceivePayment = "Receive your payment";
  let tReceivePaymentText = "We will pay the provision amount to your bank account, once the provision is vetted to be genuine.";




  const useDisablePinchZoomEffect = () => {
    useEffect(() => {
      const disablePinchZoom = (e) => {
        if (e.touches.length > 1) {
          e.preventDefault()
        }
      }
      document.addEventListener("touchmove", disablePinchZoom, { passive: false })
      return () => {
        document.removeEventListener("touchmove", disablePinchZoom)
      }
    }, [])
  }

  useDisablePinchZoomEffect();

  const newPictureRef = useRef(null);
  const howWorksRef = useRef(null);
  const pricingRef = useRef(null);
  const overviewRef = useRef(null);
  
  const footerRef = useRef(null);
  const socialsRef = useRef(null);
  const sitemapRef = useRef(null);
  
  

  const [explainerFor, setExplainerFor] = useState({
    options: [
      { label: tForDonors, value: 'forDonors' },
      { label: tForProviders, value: 'forProviders' }
    ],
    value: (!match.params.page || match.params.page !== "provide") ? 'forDonors' : 'forProviders',
  });

  useEffect(() => {
    setExplainerFor({
    options: [
      { label: tForDonors, value: 'forDonors' },
      { label: tForProviders, value: 'forProviders' }
    ],
    value: (!match.params.page || match.params.page !== "provide") ? 'forDonors' : 'forProviders',
  })}, [match.params.page, tForDonors, tForProviders]);

  const handleScrollToOverview = () => {
    window.scrollTo({left:0,
      top: 0, // overviewRef.current.offsetTop - 20,
      behavior: 'smooth'
    });
  }

  const handleScrollToTakePicture = () => {
    window.scrollTo({left:0, top: newPictureRef.current.offsetTop - 20, behavior: 'smooth'});
  }

  const handleScrollToPricing = () => {
    window.scrollTo({left:0, top: pricingRef.current.offsetTop - 20, behavior: 'smooth'});
  }

  useEffect(() => {
    if (match.params.page === "home" || match.params.page === "overview") {
      handleScrollToOverview();
      history.replace({
        pathname: "/", // generatePath(match.path, {...match.params, page: ""}),
        // search: "",
        // state: state,
      });
    }
  }, [history, match.params.page]);

  useEffect(() => {
    if (match.params.page === "pricing") {
      handleScrollToPricing();
    }
  }, [match.params.page]);
  
  useEffect(() => {
    if (match.params.page === "retail" || match.params.page === "retailer") {
      history.replace({
        pathname: "/retailers", // generatePath(match.path, {...match.params, page: ""}),
        // search: "",
        // state: state,
      });
    }
    
  }, [history, match.params.page]);

  useEffect(() => {
    if(match.params.page === "sign-in") {
      history.replace({
        pathname: "/log-in", // generatePath(match.path, {...match.params, page: "log-in"}),
        // search: "",
        // state: state,
      });
    }
    else if (match.params.page === "log-in") {
      dispatch(updateProfileState({
        localStage: "DialogAuth",
        localMode: "log-in",
      }));
    }
    else if (match.params.page === "sign-up") {
      dispatch(updateProfileState({
        localStage: "DialogAuth",
        localMode: "sign-up",
      }));
    }
    else if (match.params.page === "forgot-password") {
      dispatch(updateProfileState({
        localStage: "DialogAuth",
        localMode: "forgot-password",
      }));
    }
  }, [dispatch, history, match.params.page]);

  // temp pages ???
  useEffect(() => {

    switch(match.params.page) {
      case "email":
        // window.location.href = "mailto:hello@facedonate.org";
        window.open("mailto:hello@facedonate.org?&subject=Hey, FaceDonate Team!", '_blank');
        break;
      case "donations":
        window.open("https://facedonate.notion.site/52646e87eec64c40896e57dcddcdbf57", '_self'); // ??? temp
        break;
      case "feedback":
        window.open("https://facedonate.notion.site/c161f2d4715c420abc62c25c5f339980", '_self'); // ??? temp
        break;
      case "fundraising":
        window.open("https://facedonate.notion.site/3b84ea3740784642a8a28d54cdf35573", '_self'); // ??? temp
        break;
      case "vouchers":
        window.open("https://facedonate.notion.site/d88454023dfd452294dfc10ecb668369", '_self'); // ??? temp
        break;
      case "dashboard":
        window.open("https://facedonate.notion.site/6390ba2e8f8041dbbf33a268e04a706a", '_self'); // ??? temp
        break;
      case "impact-reporting":
        window.open("https://facedonate.notion.site/380830d97cb648f0a7731084572d2978", '_self'); // ??? temp
        break;
      case "personal":
        window.open("https://facedonate.notion.site/28e835b3fe264c83814dd666562ecfdd", '_self'); // ??? temp
        break;
      case "corporate":
        window.open("https://facedonate.notion.site/028ad73acb0b490d9a16c71c52e6413e", '_self'); // ??? temp
        break;
      case "charities":
        window.open("https://facedonate.notion.site/8aeeb8d7016145cc9bcee11b9153985e", '_self'); // ??? temp
        break;
      case "food-banks":
        window.open("https://facedonate.notion.site/160fbe89ecea454d97298f7e559e3304", '_self'); // ??? temp
        break;
      case "beneficiaries":
        window.open("https://facedonate.notion.site/72ea063b1ec14a398a56c8e81e8416ed", '_self'); // ??? temp
        break;
      case "retailers":
        window.open("https://facedonate.notion.site/ba477bb43dfe482399bdd418237c2824", '_self'); // ??? temp
        break;
      case "about":
        window.open("https://facedonate.notion.site/c0ce0eb6c7a5407692108be326be7c39", '_self'); // ??? temp
        break;
      case "demo":
        window.open("https://facedonate.notion.site/94fedca18afb4716ab7d673c817a70aa", '_self'); // ??? temp
        break;
      case "switch":
        window.open("https://facedonate.notion.site/c368a04bac3b459290e7d0d7d14b577c", '_self'); // ??? temp
        break;
      case "faq":
        window.open("https://facedonate.notion.site/d505b952da0843a7bfd6332f9ab76db0", '_self'); // ??? temp
        break;
      case "legal":
        window.open("https://facedonate.notion.site/5b818a5997d04208be71c021c8c3e3d1", '_self'); // ??? temp
        break;
      case "complaints":
        window.open("https://facedonate.notion.site/0404b2309d1b4d259e8fbea9b8ce564c", '_self'); // ??? temp
        break;
        
      default:
        // code block
    }

  }, [history, match.params.page]);
  

  const ExplainerForDonors = () => {
    return (
      <Pane>
        <Pane width="100%" minHeight="300px" alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" >
            <Pane marginLeft="auto" marginRight="auto" marginTop={16} width="250px" background="tint1" borderRadius={10} padding={10} textAlign="center">
              <Heading size={600} color="#283655" >{tTakeAPortrait}</Heading>
            </Pane>
            <Pane marginLeft="auto" marginRight="auto" maxWidth="400px" padding={10} textAlign="center">
              <Text marginTop={16} size={600} color="#283655" >{tTakeAPortraitText}</Text>
            </Pane>
          </Pane>
          <Pane flex={0.7} width="100%"  height="100%" padding={16} minWidth={200} maxWidth={300} maxHeight={230} marginLeft="auto" marginRight="auto" lineHeight={0}>
              <FaceDonateImage3 width="100%" height="100%" />
          </Pane>
        </Pane>

        <Pane width="100%" minHeight="300px" marginTop={18} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" >
            <Pane marginLeft="auto" marginRight="auto" marginTop={16} width="250px" background="tint1" borderRadius={10} padding={10} textAlign="center">
              <Heading size={600} color="#283655" >{tKnowWhom}</Heading>
            </Pane>
            <Pane marginLeft="auto" marginRight="auto" maxWidth="400px" padding={10} textAlign="center">
              <Text marginTop={16} size={600} color="#283655">{tKnowWhomText}</Text>
            </Pane>
          </Pane>
          <Pane flex={0.7} width="100%" padding={16} minWidth={200} maxWidth={300} maxHeight={230} marginLeft="auto" marginRight="auto" lineHeight={0}>
              <FaceDonateImage5 width="100%" height="100%" />
          </Pane>
        </Pane>

        <Pane width="100%" minHeight="300px" alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" >
            <Pane marginLeft="auto" marginRight="auto" marginTop={16} width="250px" background="tint1" borderRadius={10} padding={10} textAlign="center">
              <Heading size={600} color="#283655" >{tNoCash}</Heading>
            </Pane>
            <Pane marginLeft="auto" marginRight="auto" maxWidth="400px" padding={10} textAlign="center">
              <Text marginTop={16} size={600} color="#283655">{tNoCashText}</Text>
            </Pane>
          </Pane>
          <Pane flex={0.7} width="100%" padding={16} minWidth={200} maxWidth={300} maxHeight={230} marginLeft="auto" marginRight="auto" lineHeight={0}>
              <FaceDonateImage4 width="100%" height="100%" />
          </Pane>
        </Pane>

        <Pane width="100%" minHeight="300px" alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" >
            <Pane marginLeft="auto" marginRight="auto" marginTop={16} width="250px" background="tint1" borderRadius={10} padding={10} textAlign="center">
              <Heading size={600} color="#283655" >{tKnowDoGood}</Heading>
            </Pane>
            <Pane marginLeft="auto" marginRight="auto" maxWidth="400px" padding={10} textAlign="center">
              <Text marginTop={16} size={600} color="#283655">{tKnowDoGoodText}
                <Tooltip content={tKnowDoGoodTextTT}>
                  <InfoSignIcon marginLeft={4} size={16} />
                </Tooltip>
              </Text>
              
            </Pane>
          </Pane>
          <Pane flex={0.7} width="100%" padding={16} minWidth={200} maxWidth={300} maxHeight={230} marginLeft="auto" marginRight="auto" lineHeight={0}>
              <FaceDonateImage6 width="100%" height="100%" />
          </Pane>
        </Pane>
      </Pane>
    );
  };

  const ExplainerForProviders = () => {
    return (
      <Pane>
        <Pane width="100%" minHeight="300px" alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" >
            <Pane marginLeft="auto" marginRight="auto" marginTop={16} width="250px" background="tint1" borderRadius={10} padding={10} textAlign="center">
              <Heading size={600} color="#283655" >{tTakeAPortrait}</Heading>
            </Pane>
            <Pane marginLeft="auto" marginRight="auto" maxWidth="400px" padding={10} textAlign="center">
              <Text marginTop={16} size={600} color="#283655">{tTakeAPortraitTextFP}</Text>
            </Pane>
          </Pane>
          <Pane flex={0.7} width="100%"  height="100%" padding={16} minWidth={200} maxWidth={300} maxHeight={230} marginLeft="auto" marginRight="auto" lineHeight={0}>
              <FaceDonateImage3 width="100%" height="100%" />
          </Pane>
        </Pane>

        <Pane width="100%" minHeight="300px" marginTop={18} alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" >
            <Pane marginLeft="auto" marginRight="auto" marginTop={16} width="250px" background="tint1" borderRadius={10} padding={10} textAlign="center">
              <Heading size={600} color="#283655" >{tHelpWithGS}</Heading>
            </Pane>
            <Pane marginLeft="auto" marginRight="auto" maxWidth="400px" padding={10} textAlign="center">
              <Text marginTop={16} size={600} color="#283655">{tHelpWithGSText}</Text>
            </Pane>
          </Pane>
          <Pane flex={0.7} width="100%" padding={16} minWidth={200} maxWidth={300} maxHeight={230} marginLeft="auto" marginRight="auto" >
              <FdImgFood width="100%" height="100%" />
          </Pane>
        </Pane>

        <Pane width="100%" minHeight="300px" alignItems="center" display="flex" flexWrap="wrap">
          <Pane flex={1} minWidth="250px" >
            <Pane marginLeft="auto" marginRight="auto" marginTop={16} width="250px" background="tint1" borderRadius={10} padding={10} textAlign="center">
              <Heading size={600} color="#283655" >{tReceivePayment}</Heading>
            </Pane>
            <Pane marginLeft="auto" marginRight="auto" maxWidth="400px" padding={10} textAlign="center">
              <Text marginTop={16} size={600} color="#283655">{tReceivePaymentText}</Text>
            </Pane>
          </Pane>
          <Pane flex={0.7} width="100%" padding={16} minWidth={200} maxWidth={300} height={250} marginLeft="auto" marginRight="auto" >
              <FdImgBank width="100%" style={{"height": "100%"}} />
          </Pane>
        </Pane>

        
      </Pane>
    );
  };

  return (

    <Pane>
    
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto"
        paddingBottom={0}
      >

        
        {/* <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap">

          <Pane flex={1.2} minWidth="250px" textAlign={"left"}>

            <Strong is={"h1"} fontWeight={700} lineHeight={"inherit"} fontSize={48} color="#283655">
              {I18n.get('The revolutionising way')}
              <br></br>
              {I18n.get('to help people in need')}
            </Strong>

            <Button marginTop={16}
              onClick={() => {
                // className='button-light'
                window.scrollTo({left:0, top: howWorksRef.current.offsetTop - 20, behavior: 'smooth'});
              }}
            >
            {I18n.get('How does this work?')}
            </Button>

          </Pane>

          <Pane flex={0.8} width="100%" marginTop={16} maxHeight="300px" minWidth="250px" height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
            <TakeFacePicturePic height={300} />
          </Pane>

        </Pane> */}

        <div ref={overviewRef}></div>

        <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap" gap={20} >

          <Pane flex={1} minWidth="250px" textAlign={"left"}>

            {/* <Strong is={"h1"} marginBottom={0} fontWeight={700} lineHeight={"inherit"} fontSize={48} color="#283655">
              {I18n.get('Your donation gives')}
              <br></br>
              {I18n.get('much, much more')}
            </Strong> */}

            <Strong is={"h1"}
              // marginBottom={0}
              fontWeight={700} lineHeight={"inherit"} fontSize={48} color="#283655"
            >
              {I18n.get('Your donation goes 3 times further')}
            </Strong>

            {/* <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" gap={12}>
              <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={4} color="#283655">
                {I18n.get('Choice')}
              </Strong>
              <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={4} color="#283655">
                {I18n.get('Dignity')}
              </Strong>
              <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={4} color="#283655">
                {I18n.get('Efficiency')}
              </Strong>
            </Pane> */}

            {/* <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" gap={12}>
              <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={48} marginY={4} color="#283655">
                <Typewriter
                  options={{
                    strings: ['choice', 'dignity', 'efficiency'],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Strong>
            </Pane> */}
            
            {/* <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('At traditional food banks, as much as £60 per food parcel is operational costs. We eliminate the unnecessary costs and offer beneficiaries greater choice and dignity.')}
            </Paragraph> */}

            <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('We eliminate the unnecessary costs and offer beneficiaries greater choice and dignity.')}
            </Paragraph>


            {/* <Button fontSize={18} marginTop={36} paddingX={18} minWidth={140} height={48} justifyContent="center" className='button-blue'
              lineHeight={1.1}
              is={Link}
              to={{
                pathname: "new/donation", // "browse" ???
                search: "?amount=50&to=rytrcw&currency=gbp",
                // pathname: generatePath("/:locale?/:page", {...match.params, page: "face_donate"}), // "browse" ???
                // state: {},
              }}
            >
              {I18n.get('Donate and track your impact now')}
            </Button> */}
            

          </Pane>

          <Pane flex={1} width="100%" marginTop={0} minWidth="250px" height="100%" marginLeft="auto" marginRight="auto"
            textAlign="right"
            className="mobile-hide" // ???
          >
            <Pane
              // width="fit-content"
              // display="contents"
              // justifyContent={"center"}
            >
              <img src={FaceDonateScalesImage} alt={"FaceDonate - 3x Efficiency Scales"}
                // height="100%"
                width="auto"
                style={{
                  maxHeight: 390,
                }}
              />
            </Pane>
            

          </Pane>

        </Pane>

        {/* <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap" gap={20} >

          <Pane flex={1} minWidth="250px" textAlign={"left"}>

            <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('At traditional food banks, as much as £60 per food parcel is operational costs. We eliminate the unnecessary costs and offer beneficiaries greater choice and dignity.')}
            </Paragraph>

          </Pane>

          <Pane flex={1} width="100%" minWidth="250px" height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
            <Button fontSize={18} paddingX={24} minWidth={140} height={48} justifyContent="center" className='button-blue'
              is={Link}
              to={{
                pathname: generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                // state: {},
              }}
            >
              {I18n.get('Donate now')}
            </Button>
          </Pane>

        </Pane> */}

        {/* <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap" textAlign={"center"} >

          <Pane textAlign={"center"} marginLeft="auto" marginRight="auto" >

            <Strong is={"h1"} fontWeight={700} lineHeight={"inherit"} fontSize={48} marginY={20} color="#283655">
              {I18n.get('Your donation goes')}
              <br></br>
              {I18n.get('much, much further')}
            </Strong>

          </Pane>

        </Pane> */}

        {/* <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap">

          <Pane flex={1.2} minWidth="250px" textAlign={"left"}>

            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              {I18n.get('Efficiency')}
            </Strong>

            <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('At traditional food banks, as much as £60 per food parcel is operational costs. Our modern technology eliminates the unnecessary costs and offers beneficiaries greater choice and dignity.')}
            </Paragraph>

          </Pane>

          <Pane flex={0.8} width="100%" marginTop={16} maxHeight="300px" minWidth="250px" height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
            <Strong fontWeight={700} lineHeight={"inherit"} fontSize={120} marginY={0} color="#283655">
              {I18n.get('3')}
            </Strong>
            <Strong fontWeight={700} lineHeight={"inherit"} fontSize={80} marginY={0} color="#283655">
              {I18n.get('x')}
            </Strong>
            <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('greater value')}
            </Paragraph>
          </Pane>

        </Pane> */}

        

        {/* <Pane width="100%" marginTop={24} alignItems="center" display="flex" flexWrap="wrap" textAlign={"center"} gap={20} >

          <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
            {I18n.get('Join the revolution in giving!')}
          </Strong>

          <Pane textAlign="center" >
            <Button fontSize={18} paddingX={24} minWidth={140} height={48} justifyContent="center" className='button-blue'
              is={Link}
              to={{
                pathname: generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                // state: {},
              }}
            >
              {I18n.get('Donate')}
            </Button>
          </Pane>

        </Pane> */}

        {/* <Pane width="100%" marginTop={24} alignItems="center" display="flex" flexWrap="wrap" textAlign={"center"} gap={20} >

          <Pane textAlign="center" >
            <Button fontSize={18} paddingX={24} minWidth={140} height={48} justifyContent="center" className='button-blue'
              is={Link}
              to={{
                pathname: generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                // state: {},
              }}
            >
              {I18n.get('Track my donation')}
            </Button>
          </Pane>

        </Pane> */}

        {/* <Pane width="100%" marginTop={36} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane> */}

        {/* Dashboard window */}
        {true ? null :
        <Pane marginTop={36} >

          {/* <Pane textAlign={"center"} marginLeft="auto" marginRight="auto" >
            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              {I18n.get('Track your impact in real time')}
            </Strong>
          </Pane> */}

          <Pane borderRadius={10} background="#FAFBFF" elevation={2} >

            <Pane paddingX={12} paddingY={10} paddingBottom={8} borderBottom borderBottomWidth={1} borderBottomStyle="solid" borderColor="#435A6F40"
              display="flex" flexDirection={"row"} alignItems="center" >

              {/* <Pane display="flex" flexDirection={"row"} gap={7} >
                <Pane backgroundColor="#ed6a5e" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                <Pane backgroundColor="#f4bf4f" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                <Pane backgroundColor="#61c554" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
              </Pane> */}

              <Pane display="flex" flexDirection={"row"} gap={7} >
                <Pane backgroundColor="#435A6F40" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                <Pane backgroundColor="#435A6F40" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                <Pane backgroundColor="#435A6F40" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
              </Pane>

              <Pane marginLeft={18} display="flex" flexDirection={"row"} gap={8} >
                <ArrowLeftIcon color="#435A6F40" size={16} />
                <ArrowRightIcon color="#435A6F40" size={16} />
              </Pane>

              <Pane marginLeft={"auto"} marginRight={"auto"} display="flex" flexDirection={"row"} gap={8} >
                <ShieldIcon color="#435A6F40" size={16} />
                <Pane
                  // paddingX={12} backgroundColor="#435A6F10" borderRadius={4} width={200} height={20}
                >
                  <Text lineHeight={"inherit"} fontSize={14} color="#435A6F40" >{I18n.get("facedonate.org")}</Text>
                </Pane>
              </Pane>

              <Pane width={40} marginLeft={18} display="flex" flexDirection={"row"} gap={8} ></Pane>

              <Pane marginLeft={18} display="flex" flexDirection={"row"} gap={12} >
                <ShareIcon color="#435A6F40" size={15} />
                <MoreIcon color="#435A6F40" size={16} />
                {/* <MenuIcon color="#435A6F40" size={16} /> */}
              </Pane>
              

            </Pane>
            
            <Pane alignItems="flex-start" display="flex" flexWrap="wrap"
              paddingX={12}
              gap={0}
              // background="tint1"
            >

              <Pane flex={1} minWidth="250px" >

                <Pane paddingTop={18} marginBottom={12} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                  <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                    {I18n.get('Impact score')}
                  </Strong>
                </Pane>

                <Pane width="220px" marginLeft="auto" marginRight="auto" >

                  <GaugeChart id="gauge-chart-X"
                    nrOfLevels={10}
                    // arcsLength={[0.3, 0.5, 0.2]}
                    colors={['#EC4C47', '#F5CD19', '#47B881']} // colors={['#5BE12C', '#F5CD19', '#EA4228']}
                    marginInPercent={0.02}
                    cornerRadius={5}
                    percent={0.73}
                    arcWidth={0.25}
                    arcPadding={0.03}
                    hideText={true}
                    needleBaseColor="#283655"
                    needleColor="#283655"
                    style={{
                      "width": "100%"
                    }}
                  />

                  <Pane marginTop={4} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                    <Strong fontWeight={700} lineHeight={"inherit"} fontSize={24} marginY={0} color="#283655">
                      {I18n.get('73')}
                    </Strong>
                    <Text fontSize={14} color="#7B8B9A" lineHeight={"inherit"} marginY={0} >
                      {I18n.get('/100')}
                    </Text>
                  </Pane>
                  {/* <Pane paddingTop={2} textAlign="center" >
                    <Text fontSize={14} color="#7B8B9A" >{I18n.get('Impact score')}</Text>
                  </Pane> */}
                
                </Pane>

                <Pane paddingX={10} >
                  <Pane width="100%" marginTop={18} borderTop borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20" ></Pane>
                </Pane>
                

                <Pane paddingTop={18} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                  <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                    {I18n.get('Contributions')}
                  </Strong>
                </Pane>

                <Pane paddingTop={12} display={"flex"} flexWrap={"wrap"} gap={10} >
                  <Pane
                    // key={key}
                    // display={"flex"}
                    textAlign="center"
                    flex={"1"}
                    // width={32}
                    minWidth={70}
                    paddingX={8}
                    justifyContent="center"
                  >
              
                    <Pane flex="none" >
                      <Strong display="contents" flex="none" fontSize={20} color="#283655">{"£3,138"}</Strong>
                    </Pane>
                    <Pane paddingTop={2} >
                      <Text fontSize={14} color="#7B8B9A" >{I18n.get('donated')}</Text>
                    </Pane>
              
                  </Pane>
                  <Pane
                    // key={key}
                    // display={"flex"}
                    textAlign="center"
                    flex={"1"}
                    // width={32}
                    minWidth={70}
                    maxHeight={32}
                    paddingX={8}
                    justifyContent="center"
                  >
              
                    <Pane flex="none" >
                      <Strong display="contents" flex="none" fontSize={20} color="#283655">{"£8,263"}</Strong>
                    </Pane>
                    <Pane paddingTop={2} >
                      <Text fontSize={14} color="#7B8B9A" >{I18n.get("raised")}</Text>
                    </Pane>
              
                  </Pane>
                    
                  <Pane
                    // key={key}
                    // display={"flex"}
                    textAlign="center"
                    flex={"1"}
                    // width={32}
                    minWidth={70}
                    paddingX={8}
                    justifyContent="center"
                  >
              
                    <Pane flex="none" >
                      <Strong display="contents" flex="none" fontSize={20} color="#283655">{"2.6x"}</Strong>
                    </Pane>
                    <Pane paddingTop={2} >
                      <Text fontSize={14} color="#7B8B9A" >{I18n.get("engagement")}</Text>
                    </Pane>
              
                  </Pane>
                </Pane>

                <Pane width="100%" marginTop={18} paddingX={10} alignItems="center" display="flex" flexWrap="wrap">

                  <Pane flex={1} height={60} justifyContent="center" >
                    <DemoBar2 />
                  </Pane>

                  <Pane flex={"none"} height={80} justifyContent="center" >
                    <Pane paddingY={2} paddingX={2} >
                      <Text fontSize={12} color="#7B8B9A" >{I18n.get('Match funding')}</Text>
                    </Pane>
                    <Pane height={54} justifyContent="center" >
                      <DemoRingProgress />
                    </Pane>
                    
                  </Pane>

                </Pane>
                
                

                {/* <Pane marginTop={18} paddingX={10} >
                  <Pane height={200} justifyContent="center" >
                    <DemoHistogram />
                  </Pane>
                </Pane> */}

                <Pane marginTop={18} paddingX={10} >
                  <Pane height={176} justifyContent="center" >
                    <DemoRadialBar />
                  </Pane>
                </Pane>

                {/* <Pane marginTop={18} paddingX={10} height={100} justifyContent="center" >
                  <DemoBullet />
                </Pane> */}

                {/* <Pane marginTop={18} paddingX={10} >
                  <Pane height={260} justifyContent="center" >
                    <DemoDualAxes />
                  </Pane>
                </Pane> */}

                {/* <Pane marginTop={18} paddingX={10} >
                  <Pane height={260} justifyContent="center" >
                    <DemoArea />
                  </Pane>
                </Pane> */}

                

                {/* <Pane paddingY={18} display={"flex"} flexWrap={"wrap"} justifyContent="center" >
                  
                  <Button fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                    disabled={false}
                    onClick={() => {
                      
                    }}
                  >
                    {I18n.get('View all transactions')}
                  </Button>
                </Pane> */}


              </Pane>
              

              <Pane flex={1} minWidth="250px" >

                <Pane paddingTop={18} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                  {/* <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                    {I18n.get('UN Sustainable Development Goals')}
                  </Strong> */}
                  <Pane
                    // width="fit-content"
                    // display="contents"
                    // justifyContent={"center"}
                  >
                    <img src={FaceDonateSdgsEmblemHImage} alt={"FaceDonate - UN SDGs Emblem"}
                      // height="100%"
                      width="auto"
                      style={{
                        maxHeight: 390,
                      }}
                    />
                  </Pane>
                </Pane>

                {/* <Pane marginTop={12} paddingX={10} height={100} justifyContent="center" >
                  <DemoColumn2 />
                </Pane> */}

                <Pane marginTop={18} paddingX={10} >
                  <Pane height={135} justifyContent="center" >
                    <DemoTreemap />
                  </Pane>
                </Pane>

                <Pane paddingX={10} >
                  <Pane width="100%" marginTop={18} borderTop borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20" ></Pane>
                </Pane>
                
                <Pane paddingTop={18} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                  <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                    {I18n.get('Use of funds')}
                  </Strong>
                </Pane>

                <Pane paddingTop={12} display={"flex"} flexWrap={"wrap"} gap={10} >
                  <Pane
                    // key={key}
                    // display={"flex"}
                    textAlign="center"
                    flex={"1"}
                    // width={32}
                    minWidth={70}
                    paddingX={8}
                    justifyContent="center"
                  >
              
                    <Pane flex="none" >
                      <Strong display="contents" flex="none" fontSize={20} color="#283655">{"12"}</Strong>
                    </Pane>
                    <Pane paddingTop={2} >
                      <Text fontSize={14} color="#7B8B9A" >{I18n.get('initiatives')}</Text>
                    </Pane>
              
                  </Pane>
                  <Pane
                    // key={key}
                    // display={"flex"}
                    textAlign="center"
                    flex={"1"}
                    // width={32}
                    minWidth={70}
                    paddingX={8}
                    justifyContent="center"
                  >
              
                    <Pane flex="none" >
                      <Strong display="contents" flex="none" fontSize={20} color="#283655">{"247"}</Strong>
                    </Pane>
                    <Pane paddingTop={2} >
                      <Text fontSize={14} color="#7B8B9A" >{I18n.get('adult days')}</Text>
                    </Pane>
              
                  </Pane>
                  <Pane
                    // key={key}
                    // display={"flex"}
                    textAlign="center"
                    flex={"1"}
                    // width={32}
                    minWidth={70}
                    maxHeight={32}
                    paddingX={8}
                    justifyContent="center"
                  >
              
                    <Pane flex="none" >
                      <Strong display="contents" flex="none" fontSize={20} color="#283655">{"458"}</Strong>
                    </Pane>
                    <Pane paddingTop={2} >
                      <Text fontSize={14} color="#7B8B9A" >{I18n.get("child days")}</Text>
                    </Pane>
              
                  </Pane>
                </Pane>

                <Pane marginTop={12} paddingX={10} height={200} justifyContent="center" >
                  <DemoColumn />
                </Pane>


                <Pane marginTop={18} paddingX={10} >
                  {/* <Pane paddingY={4} paddingX={8} >
                    <Text fontSize={14} color="#7B8B9A" >{I18n.get('Use of funds')}</Text>
                  </Pane> */}
                  <Pane height={60} justifyContent="center" >
                    <DemoBar3 />
                  </Pane>
                </Pane>

                <Pane paddingX={10} >
                  <Pane width="100%" marginTop={18} borderTop borderTopWidth={0} borderTopStyle="solid" borderColor="#435A6F20" ></Pane>
                </Pane>
                
                {/* <Pane paddingTop={18} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                  <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                    {I18n.get('Top initiatives')}
                  </Strong>
                </Pane> */}

                {/* <Pane marginTop={18} paddingX={10} >
                  <Pane height={190} justifyContent="center" >
                    <DemoRadialBar />
                  </Pane>
                </Pane> */}

                {/* <Pane marginTop={18} paddingX={10} >
                  <Pane height={110} justifyContent="center" >
                    <DemoCirclePacking />
                  </Pane>
                </Pane> */}

                {/* <Pane paddingY={18} display={"flex"} flexWrap={"wrap"} justifyContent="center" >
                  
                  <Button fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                    disabled={false}
                    onClick={() => {
                      
                    }}
                  >
                    {I18n.get('View all initiatives')}
                  </Button>
                </Pane> */}


              </Pane>

            </Pane>

            {/* Mobile phone */}
            {/* <Fragment>
              <Pane
                height={0}
              >
                <Pane
                  // marginTop={36}
                  width={206}
                  height={417}
                  position="relative"
                  marginLeft="auto"
                  right={-30}
                  bottom={417}
                  zIndex={1}
                >
                
                  <Pane
                    width={"100%"}
                    height={"100%"}
                    elevation={3}
                    border
                    borderRadius={24}
                    borderWidth={6} borderTopStyle="solid" borderColor="#283655"
                    background="#FFFFFF"
                  >

                    <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >
                        <Pane
                          flex={1}
                          height={8}
                          marginLeft={30}
                          borderTopLeftRadius={0}
                          borderTopRightRadius={4}
                          borderBottomLeftRadius={0}
                          borderBottomRightRadius={0}
                          background="#FFFFFF"
                          zIndex={1}
                        ></Pane>

                        <Pane
                          width={124}
                          height={8}
                          marginLeft={-6}
                          marginRight={-6}
                          borderTopLeftRadius={0}
                          borderTopRightRadius={0}
                          borderBottomLeftRadius={-10}
                          borderBottomRightRadius={10}
                          background="#283655"
                        ></Pane>

                        <Pane
                          flex={1}
                          height={8}
                          marginRight={30}
                          borderTopLeftRadius={4}
                          borderTopRightRadius={0}
                          borderBottomLeftRadius={0}
                          borderBottomRightRadius={0}
                          background="#FFFFFF"
                        ></Pane>
                    </Pane>

                    <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >

                        <Pane
                          flex={1}
                          height={8}
                          marginLeft={30}
                          background="#FFFFFF"
                          zIndex={2}
                        ></Pane>

                        <Pane
                          width={112}
                          height={8}
                          marginLeft={0}
                          marginRight={0}
                          borderTopLeftRadius={0}
                          borderTopRightRadius={0}
                          borderBottomLeftRadius={10}
                          borderBottomRightRadius={10}
                          background="#283655"
                        ></Pane>

                        <Pane
                          flex={1}
                          height={8}
                          marginRight={30}
                          background="#FFFFFF"
                        ></Pane>


                    </Pane>

                    <Pane
                      width={"100%"}
                      // height={60}
                      // background="green"
                    >

                      <DashboardMobileView />

                    </Pane>

                  </Pane>
                </Pane>
              </Pane>
            </Fragment> */}

            {/* <Pane paddingX={12} paddingY={10} display="flex" ></Pane> */}

          </Pane>

        </Pane>
        }


        {/* #00358F */}

        {/* #336EC4 */}

        {/* Desktop */}
        <Fragment>
          <Pane
            // height={0}
            marginTop={36}
          >
            <Pane
              // marginTop={36}
              // width={"100%"}
              // height={417}
              // position="relative"
              // marginLeft={20}
              // marginRight={20}
              // right={-30}
              // bottom={417}
              // zIndex={1}
            >
            
              <Pane
                // width={"100%"}
                height={"100%"}
                elevation={3}
                border
                borderRadius={11}
                borderWidth={1}
                borderStyle="solid"
                borderColor="#283655"
                background="#FFFFFF"
              >

                <Pane
                  width={"100%"}
                  height={"100%"}
                  // elevation={3}
                  // border
                  borderRadius={10}
                  // borderWidth={6}
                  // borderStyle="solid"
                  // borderColor="#7E8A98"
                  background="#7E8A98"
                  padding={6}
                >

                  <Pane
                    width={"auto"}
                    height={"100%"}
                    // elevation={3}
                    border
                    borderRadius={10}
                    // borderWidth={1}
                    borderStyle="solid"
                    borderColor="#283655"
                    background="#FFFFFF"
                  >

                    {/* <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >
                      <Pane
                        flex={1}
                        height={8}
                        marginLeft={30}
                        borderTopLeftRadius={0}
                        borderTopRightRadius={4}
                        borderBottomLeftRadius={0}
                        borderBottomRightRadius={0}
                        background="#FFFFFF"
                        zIndex={1}
                      ></Pane>

                      <Pane
                        width={124}
                        height={8}
                        marginLeft={-6}
                        marginRight={-6}
                        borderTopLeftRadius={0}
                        borderTopRightRadius={0}
                        borderBottomLeftRadius={-10}
                        borderBottomRightRadius={10}
                        background="#283655"
                      ></Pane>

                      <Pane
                        flex={1}
                        height={8}
                        marginRight={30}
                        borderTopLeftRadius={4}
                        borderTopRightRadius={0}
                        borderBottomLeftRadius={0}
                        borderBottomRightRadius={0}
                        background="#FFFFFF"
                      ></Pane>
                    </Pane> */}

                    {/* <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >

                      <Pane
                        flex={1}
                        height={8}
                        marginLeft={30}
                        background="#FFFFFF"
                        zIndex={2}
                      ></Pane>

                      <Pane
                        width={112}
                        height={8}
                        marginLeft={0}
                        marginRight={0}
                        borderTopLeftRadius={0}
                        borderTopRightRadius={0}
                        borderBottomLeftRadius={10}
                        borderBottomRightRadius={10}
                        background="#283655"
                      ></Pane>

                      <Pane
                        flex={1}
                        height={8}
                        marginRight={30}
                        background="#FFFFFF"
                      ></Pane>


                    </Pane> */}

                    {/* <Pane
                      width={"100%"}
                      height={60}
                      background="green"
                    > </Pane> */}

                    <Pane >

                      {/* <Pane textAlign={"center"} marginLeft="auto" marginRight="auto" >
                        <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
                          {I18n.get('Track your impact in real time')}
                        </Strong>
                      </Pane> */}

                      <Pane
                        borderRadius={10}
                        background="#FAFBFF"
                        elevation={2}
                      >

                        <Pane paddingX={12} paddingY={10} paddingBottom={8} borderBottom borderBottomWidth={1} borderBottomStyle="solid" borderColor="#435A6F40"
                          display="flex" flexDirection={"row"} alignItems="center"
                        >

                          {/* <Pane display="flex" flexDirection={"row"} gap={7} >
                            <Pane backgroundColor="#ed6a5e" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                            <Pane backgroundColor="#f4bf4f" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                            <Pane backgroundColor="#61c554" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                          </Pane> */}

                          <Pane display="flex" flexDirection={"row"} gap={7} >
                            <Pane backgroundColor="#435A6F40" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                            <Pane backgroundColor="#435A6F40" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                            <Pane backgroundColor="#435A6F40" width={12} height={12} border={"1px solid #0000000f"} borderRadius={"100%"} ></Pane>
                          </Pane>

                          <Pane marginLeft={18} display="flex" flexDirection={"row"} gap={8} >
                            <ArrowLeftIcon color="#435A6F40" size={16} />
                            <ArrowRightIcon color="#435A6F40" size={16} />
                          </Pane>

                          <Pane marginLeft={"auto"} marginRight={"auto"} display="flex" flexDirection={"row"} gap={8} >
                            <ShieldIcon color="#435A6F40" size={16} />
                            <Pane
                              // paddingX={12} backgroundColor="#435A6F10" borderRadius={4} width={200} height={20}
                            >
                              <Text lineHeight={"inherit"} fontSize={14} color="#435A6F40" >{I18n.get("facedonate.org")}</Text>
                            </Pane>
                          </Pane>

                          <Pane width={40} marginLeft={18} display="flex" flexDirection={"row"} gap={8} ></Pane>

                          <Pane marginLeft={18} display="flex" flexDirection={"row"} gap={12} >
                            <ShareIcon color="#435A6F40" size={15} />
                            <MoreIcon color="#435A6F40" size={16} />
                            {/* <MenuIcon color="#435A6F40" size={16} /> */}
                          </Pane>
                          

                        </Pane>

                        <Pane background="#FFFFFF"
                          paddingX={6}
                          paddingTop={8} 
                        >

                          <Pane
                            alignItems="center" display="flex" flexWrap="wrap"
                            // background="#FFFFFF" // #FAFBFF // #F5F6F7 // #435A6F20
                            // borderRadius={10}
                            // padding={8}
                          >

                            <Pane alignItems="center" display="flex" flexWrap="wrap">

                              <Pane width={28} height={28} padding={0} marginLeft={8} marginRight="auto" alignItems="center" display="flex" flexWrap="wrap">
                                <Fd96 width="100%" height="100%" opacity={0.4} />
                              </Pane>
                              
                              {/* <Pane marginLeft={6} paddingTop={6} paddingBottom={4} paddingX={4}
                                className="mobile-hide"
                              >
                                <Pane width="100%" padding={0} marginBottom={2} height={1.5} background="#435A6F40" borderRadius={1} ></Pane>
                                  <Strong fontSize={16} fontWeight={500} color="#435A6F40" marginTop="auto" marginBottom="auto" borderRadius={10} >FACE DONATE</Strong>
                                <Pane width="100%" padding={0} marginTop={2} height={1.5} background="#435A6F40" borderRadius={1} ></Pane>
                              </Pane> */}
                              

                            </Pane>

                            <Pane marginLeft="auto" marginRight={12} alignItems="center" display="flex" >
                              <SearchIcon color="#435A6F40" size={16} />
                            </Pane>

                            <Pane marginLeft="inherit" marginRight={8} alignItems="center" display="flex" flexWrap="wrap" >
                              {/* <ProfileIcon color="#283655" width={24} height="auto" /> */}
                              <MenuIcon color="#435A6F40" size={16} />
                              <Strong marginLeft={8} fontSize={16} color={"#435A6F40"} >{I18n.get("Menu")}</Strong>
                            </Pane>
                            
                          </Pane>

                        </Pane>
                        
                        <Pane alignItems="flex-start" display="flex" flexWrap="wrap"
                          paddingX={10}
                          gap={0}
                          // background="tint1"
                          background="#FFFFFF"
                          borderRadius={11}
                        >

                          <Pane flex={1} minWidth="250px" >

                            <Pane paddingTop={12} marginBottom={12} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                              <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                                {I18n.get('Impact score')}
                              </Strong>
                            </Pane>

                            <Pane width="220px" marginLeft="auto" marginRight="auto" >

                              <GaugeChart id="gauge-chart-X"
                                nrOfLevels={10}
                                // arcsLength={[0.3, 0.5, 0.2]}
                                colors={['#EC4C47', '#F5CD19', '#47B881']} // colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                marginInPercent={0.02}
                                cornerRadius={5}
                                percent={0.73}
                                arcWidth={0.25}
                                arcPadding={0.03}
                                hideText={true}
                                needleBaseColor="#283655"
                                needleColor="#283655"
                                style={{
                                  "width": "100%"
                                }}
                              />

                              <Pane marginTop={4} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                                <Strong fontWeight={700} lineHeight={"inherit"} fontSize={24} marginY={0} color="#283655">
                                  {I18n.get('73')}
                                </Strong>
                                <Text fontSize={14} color="#7B8B9A" lineHeight={"inherit"} marginY={0} >
                                  {I18n.get('/100')}
                                </Text>
                              </Pane>
                              {/* <Pane paddingTop={2} textAlign="center" >
                                <Text fontSize={14} color="#7B8B9A" >{I18n.get('Impact score')}</Text>
                              </Pane> */}
                            
                            </Pane>

                            <Pane paddingX={10} >
                              <Pane width="100%" marginTop={18} borderTop borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20" ></Pane>
                            </Pane>
                            

                            <Pane paddingTop={18} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                              <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                                {I18n.get('Contributions')}
                              </Strong>
                            </Pane>

                            <Pane paddingTop={12} display={"flex"} flexWrap={"wrap"} gap={10} >
                              <Pane
                                // key={key}
                                // display={"flex"}
                                textAlign="center"
                                flex={"1"}
                                // width={32}
                                minWidth={70}
                                paddingX={8}
                                justifyContent="center"
                              >
                          
                                <Pane flex="none" >
                                  <Strong display="contents" flex="none" fontSize={20} color="#283655">{"£3,138"}</Strong>
                                </Pane>
                                <Pane paddingTop={2} >
                                  <Text fontSize={14} color="#7B8B9A" >{I18n.get('donated')}</Text>
                                </Pane>
                          
                              </Pane>
                              <Pane
                                // key={key}
                                // display={"flex"}
                                textAlign="center"
                                flex={"1"}
                                // width={32}
                                minWidth={70}
                                maxHeight={32}
                                paddingX={8}
                                justifyContent="center"
                              >
                          
                                <Pane flex="none" >
                                  <Strong display="contents" flex="none" fontSize={20} color="#283655">{"£8,263"}</Strong>
                                </Pane>
                                <Pane paddingTop={2} >
                                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("raised")}</Text>
                                </Pane>
                          
                              </Pane>
                                
                              <Pane
                                // key={key}
                                // display={"flex"}
                                textAlign="center"
                                flex={"1"}
                                // width={32}
                                minWidth={70}
                                paddingX={8}
                                justifyContent="center"
                              >
                          
                                <Pane flex="none" >
                                  <Strong display="contents" flex="none" fontSize={20} color="#283655">{"2.6x"}</Strong>
                                </Pane>
                                <Pane paddingTop={2} >
                                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("engagement")}</Text>
                                </Pane>
                          
                              </Pane>
                            </Pane>

                            <Pane width="100%" marginTop={18} paddingX={10} alignItems="center" display="flex" flexWrap="wrap">

                              <Pane flex={1} height={60} justifyContent="center" >
                                <DemoBar2 />
                              </Pane>

                              <Pane flex={"none"} height={80} justifyContent="center" >
                                <Pane paddingY={2} paddingX={2} >
                                  <Text fontSize={12} color="#7B8B9A" >{I18n.get('Match funding')}</Text>
                                </Pane>
                                <Pane height={54} justifyContent="center" >
                                  <DemoRingProgress />
                                </Pane>
                                
                              </Pane>

                            </Pane>
                            
                            

                            {/* <Pane marginTop={18} paddingX={10} >
                              <Pane height={200} justifyContent="center" >
                                <DemoHistogram />
                              </Pane>
                            </Pane> */}

                            <Pane marginTop={18} paddingX={10} >
                              <Pane height={176} justifyContent="center" >
                                <DemoRadialBar />
                              </Pane>
                            </Pane>

                            {/* <Pane marginTop={18} paddingX={10} height={100} justifyContent="center" >
                              <DemoBullet />
                            </Pane> */}

                            {/* <Pane marginTop={18} paddingX={10} >
                              <Pane height={260} justifyContent="center" >
                                <DemoDualAxes />
                              </Pane>
                            </Pane> */}

                            {/* <Pane marginTop={18} paddingX={10} >
                              <Pane height={260} justifyContent="center" >
                                <DemoArea />
                              </Pane>
                            </Pane> */}

                            

                            {/* <Pane paddingY={18} display={"flex"} flexWrap={"wrap"} justifyContent="center" >
                              
                              <Button fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                                disabled={false}
                                onClick={() => {
                                  
                                }}
                              >
                                {I18n.get('View all transactions')}
                              </Button>
                            </Pane> */}


                          </Pane>
                          

                          <Pane flex={1} minWidth="250px" >

                            <Pane
                              marginTop={12}
                              height={28}
                              marginLeft="auto" marginRight="auto" textAlign="center"
                            >
                              {/* <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                                {I18n.get('UN Sustainable Development Goals')}
                              </Strong> */}
                              <Pane
                                // width="fit-content"
                                height={"100%"}
                                display="contents"
                                justifyContent={"center"}
                              >
                                <img src={FaceDonateSdgsEmblemHImage} alt={"FaceDonate - UN SDGs Emblem"}
                                  height={"100%"}
                                  // objectFit="contain"
                                  // width={"100%"}
                                />
                              </Pane>
                            </Pane>

                            {/* <Pane marginTop={12} paddingX={10} height={100} justifyContent="center" >
                              <DemoColumn2 />
                            </Pane> */}

                            <Pane marginTop={12} paddingX={10} >
                              <Pane height={135} justifyContent="center" >
                                <DemoTreemap />
                              </Pane>
                            </Pane>

                            <Pane paddingX={10} >
                              <Pane width="100%" marginTop={18} borderTop borderTopWidth={1} borderTopStyle="solid" borderColor="#435A6F20" ></Pane>
                            </Pane>
                            
                            <Pane paddingTop={18} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                              <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                                {I18n.get('Use of funds')}
                              </Strong>
                            </Pane>

                            <Pane paddingTop={12} display={"flex"} flexWrap={"wrap"} gap={10} >
                              <Pane
                                // key={key}
                                // display={"flex"}
                                textAlign="center"
                                flex={"1"}
                                // width={32}
                                minWidth={70}
                                paddingX={8}
                                justifyContent="center"
                              >
                          
                                <Pane flex="none" >
                                  <Strong display="contents" flex="none" fontSize={20} color="#283655">{"12"}</Strong>
                                </Pane>
                                <Pane paddingTop={2} >
                                  <Text fontSize={14} color="#7B8B9A" >{I18n.get('initiatives')}</Text>
                                </Pane>
                          
                              </Pane>
                              <Pane
                                // key={key}
                                // display={"flex"}
                                textAlign="center"
                                flex={"1"}
                                // width={32}
                                minWidth={70}
                                paddingX={8}
                                justifyContent="center"
                              >
                          
                                <Pane flex="none" >
                                  <Strong display="contents" flex="none" fontSize={20} color="#283655">{"247"}</Strong>
                                </Pane>
                                <Pane paddingTop={2} >
                                  <Text fontSize={14} color="#7B8B9A" >{I18n.get('adult days')}</Text>
                                </Pane>
                          
                              </Pane>
                              <Pane
                                // key={key}
                                // display={"flex"}
                                textAlign="center"
                                flex={"1"}
                                // width={32}
                                minWidth={70}
                                maxHeight={32}
                                paddingX={8}
                                justifyContent="center"
                              >
                          
                                <Pane flex="none" >
                                  <Strong display="contents" flex="none" fontSize={20} color="#283655">{"458"}</Strong>
                                </Pane>
                                <Pane paddingTop={2} >
                                  <Text fontSize={14} color="#7B8B9A" >{I18n.get("child days")}</Text>
                                </Pane>
                          
                              </Pane>
                            </Pane>

                            <Pane marginTop={12} paddingX={10} height={200} justifyContent="center" >
                              <DemoColumn />
                            </Pane>



                            <Pane marginTop={18} paddingX={10} >
                              {/* <Pane paddingY={4} paddingX={8} >
                                <Text fontSize={14} color="#7B8B9A" >{I18n.get('Use of funds')}</Text>
                              </Pane> */}
                              <Pane height={60} justifyContent="center" >
                                <DemoBar3 />
                              </Pane>
                            </Pane>

                            <Pane paddingX={10} >
                              <Pane width="100%" marginTop={18} borderTop borderTopWidth={0} borderTopStyle="solid" borderColor="#435A6F20" ></Pane>
                            </Pane>
                            
                            {/* <Pane paddingTop={18} height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
                              <Strong fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={0} color="#283655">
                                {I18n.get('Top initiatives')}
                              </Strong>
                            </Pane> */}

                            {/* <Pane marginTop={18} paddingX={10} >
                              <Pane height={190} justifyContent="center" >
                                <DemoRadialBar />
                              </Pane>
                            </Pane> */}

                            {/* <Pane marginTop={18} paddingX={10} >
                              <Pane height={110} justifyContent="center" >
                                <DemoCirclePacking />
                              </Pane>
                            </Pane> */}

                            {/* <Pane paddingY={18} display={"flex"} flexWrap={"wrap"} justifyContent="center" >
                              
                              <Button fontSize={14} paddingX={12} minWidth={70} height={32} justifyContent="center" marginLeft="auto" marginRight="auto"
                                disabled={false}
                                onClick={() => {
                                  
                                }}
                              >
                                {I18n.get('View all initiatives')}
                              </Button>
                            </Pane> */}


                          </Pane>

                        </Pane>

                        {/* Mobile phone */}
                        <Fragment>
                          <Pane
                            height={0}
                            className="mobile-hide"
                          >
                            <Pane
                              // marginTop={36}
                              width={206}
                              height={417}
                              position="relative"
                              marginLeft="auto"
                              right={-30}
                              bottom={410}
                              zIndex={1}
                            >
                            
                              <Pane
                                width={"100%"}
                                height={"100%"}
                                elevation={3}
                                border
                                borderRadius={24}
                                borderWidth={6} borderTopStyle="solid" borderColor="#283655"
                                background="#FFFFFF"
                              >

                                <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >
                                  <Pane
                                    flex={1}
                                    height={8}
                                    marginLeft={30}
                                    borderTopLeftRadius={0}
                                    borderTopRightRadius={4}
                                    borderBottomLeftRadius={0}
                                    borderBottomRightRadius={0}
                                    background="#FFFFFF"
                                    zIndex={1}
                                  ></Pane>

                                  <Pane
                                    width={124}
                                    height={8}
                                    marginLeft={-6}
                                    marginRight={-6}
                                    borderTopLeftRadius={0}
                                    borderTopRightRadius={0}
                                    borderBottomLeftRadius={-10}
                                    borderBottomRightRadius={10}
                                    background="#283655"
                                  ></Pane>

                                  <Pane
                                    flex={1}
                                    height={8}
                                    marginRight={30}
                                    borderTopLeftRadius={4}
                                    borderTopRightRadius={0}
                                    borderBottomLeftRadius={0}
                                    borderBottomRightRadius={0}
                                    background="#FFFFFF"
                                  ></Pane>
                                </Pane>

                                <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >

                                  <Pane
                                    flex={1}
                                    height={8}
                                    marginLeft={30}
                                    background="#FFFFFF"
                                    zIndex={2}
                                  ></Pane>

                                  <Pane
                                    width={112}
                                    height={8}
                                    marginLeft={0}
                                    marginRight={0}
                                    borderTopLeftRadius={0}
                                    borderTopRightRadius={0}
                                    borderBottomLeftRadius={10}
                                    borderBottomRightRadius={10}
                                    background="#283655"
                                  ></Pane>

                                  <Pane
                                    flex={1}
                                    height={8}
                                    marginRight={30}
                                    background="#FFFFFF"
                                  ></Pane>


                                </Pane>

                                <Pane
                                  // background="#F5F6F7" // #FAFBFF // #F5F6F7
                                  borderRadius={4}
                                  padding={4}
                                  marginX={4}
                                  marginTop={-2}
                                >

                                  <Pane alignItems="flex-start" display="flex" flexWrap="wrap" >

                                    <Pane width={18} height={18} padding={0} marginLeft={8} marginRight="auto">
                                      <Fd96 width="100%" height="100%" opacity={0.4} />
                                    </Pane>

                                    <Pane width={18} height={18} padding={0} marginLeft="auto" marginRight={8} >
                                      {/* <ProfileIcon color="#283655" width={24} height="auto" /> */}
                                      <MenuIcon color="#435A6F40" size={16} />
                                    </Pane>
                                    
                                  </Pane>

                                </Pane>

                                <Pane
                                  // width={"100%"}
                                  // height={60}
                                  // background="green"
                                >

                                  <DashboardMobileView />

                                </Pane>

                              </Pane>
                            </Pane>
                          </Pane>
                        </Fragment>

                        {/* <Pane paddingX={12} paddingY={10} display="flex" ></Pane> */}

                      </Pane>

                    </Pane>

                  </Pane>

                </Pane>

              </Pane>
            </Pane>
          </Pane>

          {/* Laptop bottom */}
          <Fragment>
            <Pane
              height={18}
              marginTop={-1}
              marginLeft={-40}
              marginRight={-40}
              className="mobile-hide"
            >
            
              <Pane
                height={"100%"}
                elevation={3}
                border
                borderTopLeftRadius={6}
                borderTopRightRadius={6}
                borderBottomLeftRadius={18}
                borderBottomRightRadius={18}
                borderWidth={1}
                borderStyle="solid"
                borderColor="#283655"
                background="#7E8A98"
              >

                <Pane
                  width={104}
                  marginTop={-1}
                  marginLeft={"auto"}
                  marginRight={"auto"}
                  height={10}
                  borderTopLeftRadius={2}
                  borderTopRightRadius={2}
                  borderBottomLeftRadius={6}
                  borderBottomRightRadius={6}
                  borderWidth={1}
                  borderStyle="solid"
                  // borderTopStyle="none"
                  borderColor="#283655"
                  background="#FAFBFF30"
                />

              </Pane>
            </Pane>
          </Fragment>
        </Fragment>

        {/* Features */}
        <Pane width="100%" marginTop={36} >

          {/* <Pane textAlign={"center"} marginLeft="auto" marginRight="auto" >
            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              <Gradient dir="left-to-right" from="#007CF0" to="#00DFD8">
                {I18n.get('For forward-thinkers,')}
              </Gradient>
              {" "}
              <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
                {I18n.get('not')}
              </Gradient>
              {" "}
              <Gradient dir="left-to-right" from="#FF4D4D" to="#F9CB28">
                {I18n.get('box-shifters')}
              </Gradient>
            </Strong>
          </Pane> */}

          {/* For efficiency-seekers */}

          <Pane textAlign={"center"} marginLeft="auto" marginRight="auto" >
            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                {I18n.get('All-in-one')}
              </Gradient>
              {" "}
              <Gradient dir="left-to-right" from="#7928CA" to="#FF0080">
                {I18n.get('platform')}
              </Gradient>
              {" "}
              <Gradient dir="left-to-right" from="#FF4D4D" to="#F9CB28">
                {I18n.get('for forward-thinkers.')}
              </Gradient>
            </Strong>
          </Pane>

          <Pane marginTop={36} display="flex" flexWrap="wrap" gap={20} >

            <Pane flex={1} minWidth={230} >
              
              <DashboardIcon color="#283655" size={24} />

              <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={16} color="#283655">
                {I18n.get('Dashboard to showcase impact')}
              </Strong>

              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("UN-aligned metrics")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Ready-to-share reports")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("ESG visibility")}</Text>
              </Pane>

            </Pane>

            <Pane flex={1} minWidth={230} >
              
              <ChartIcon color="#283655" size={24} />

              <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={16} color="#283655">
                {I18n.get('Tools to enhance engagement')}
              </Strong>

              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Single platform")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Impact tracking")}</Text> {/*  End-to-end tracking */}
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Personalised feedback")}</Text>
              </Pane>

            </Pane>

            <Pane flex={1} minWidth={230} >
              
              <AutomaticUpdatesIcon color="#283655" size={24} />

              <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={18} marginY={16} color="#283655">
                {I18n.get('Features for full automation')}
              </Strong>

              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Real-time reporting")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Easy match funding")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <TickIcon color="#47B881" size={16} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Integrated Gift Aid")}</Text>
              </Pane>

            </Pane>


          </Pane>

          {/* <Pane padding={4} marginTop={36} >
            <img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing" />
          </Pane> */}

        </Pane>

        {/* <Pane width="100%" marginTop={36} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane> */}

        <Fragment>
          <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap">
            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              {I18n.get('Endorsed by global corporates')}
            </Strong>
          </Pane>
          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" gap={20} background="#FAFBFF"
            borderRadius={10} elevation={2}
          >

            <Pane flex={1.2} minWidth="250px" textAlign={"left"}>

              <Pane
                borderRadius={20}
                borderTopLeftRadius={10}
                borderBottomLeftRadius={10}
                background="#00358F" padding={24}
              >

                <Pane width="fit-content" >
                  <Pane maxWidth={140} >
                    <img src={process.env.PUBLIC_URL + '/media/other/logo-baringa-white.png'} alt={"Baringa Partners, Business management consultant in London, England"}
                      // height="100%"
                      width="100%"
                    />
                  </Pane>
                </Pane>

                <Paragraph marginTop={20} fontWeight={600} fontStyle="italic" lineHeight={"inherit"} fontSize={20} color="#FFFFFF">
                  {I18n.get(`"This platform is great! It closes the loop between donors and the charity. We see precisely how our donations are being used to help."`)}
                </Paragraph>

                <Paragraph marginTop={40} lineHeight={"inherit"} fontSize={"1.125rem"} color="#FFFFFF">
                  {I18n.get('Martyn Smith')}
                </Paragraph>

                <Paragraph fontWeight={700} lineHeight={"inherit"} fontSize={"1.125rem"} color="#FFFFFF">
                  {I18n.get('Partner, Baringa Partners')}
                </Paragraph>

              </Pane>
              

            </Pane>

            <Pane flex={0.8} width="100%" maxHeight="300px" minWidth="250px" height="100%" padding={20} marginLeft="auto" marginRight="auto" textAlign="center">
              <Strong fontWeight={700} lineHeight={"inherit"} fontSize={70} marginY={0} color="#00358F">
                {I18n.get('10')}
              </Strong>
              <Strong fontWeight={700} lineHeight={"inherit"} fontSize={50} marginY={0} color="#00358F">
                {I18n.get('x')}
              </Strong>
              <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#00358F">
                {I18n.get('employee engagement compared to traditional CSR campaigns')}
              </Paragraph>
            </Pane>

          </Pane>

          <Pane
            width="100%"
            // height={60}
            marginTop={36}
            display="flex"
            alignItems="center" 
            // flexWrap="wrap"
            gap={20}
            // background="green"
            overflow="scroll"
            style={{
              // "-webkit-mask-image": "linear-gradient(90deg, #0000 0%, #000 8%, #000 100%, #0000 100%)",
            }}
            className={'hide-scrollbar'}
          >
            <Pane flex={"1 0 140px"} display="inline-block" marginLeft="auto" marginRight="auto" >
              <Pane position="relative" maxWidth={130} marginLeft="auto" marginRight="auto" >
                <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-baringa.png'} alt={"Baringa Partners, Business management consultant in London, England"} height="auto" />
              </Pane>
            </Pane>
            <Pane flex={"1 0 140px"} display="inline-block" marginLeft="auto" marginRight="auto" >
              <Pane position="relative" maxWidth={130} marginLeft="auto" marginRight="auto" >
                <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-derigo.png'} alt={"De Rigo is a world leader in the design, production and distribution of premium-quality prescription eyewear frames and sunglasses."} height="auto" />
              </Pane>
            </Pane>
            <Pane flex={"1 0 140px"} display="inline-block" marginLeft="auto" marginRight="auto" >
              <Pane position="relative" maxWidth={130} marginLeft="auto" marginRight="auto" >
                <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-flyweb.png'} alt={"Flyweb. Siamo un'agenzia multidisciplinare, partner strategico e creativo per realtà ambiziose e dinamiche."} height="auto" />
              </Pane>
            </Pane>
            <Pane flex={"1 0 140px"} display="inline-block" marginLeft="auto" marginRight="auto" >
              <Pane position="relative" maxWidth={130} marginLeft="auto" marginRight="auto" >
                <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-giesse.png'} alt={"Giesse Risarcimento Danni. Ci chiamiamo Giesse e lavoriamo al fianco di chi subisce ingiustamente un danno, difendendolo dai poteri forti dei colossi assicurativi affinché possa ottenere il giusto risarcimento."} height="auto" />
              </Pane>
            </Pane>
            <Pane flex={"1 0 140px"} display="inline-block" marginLeft="auto" marginRight="auto" >
              <Pane position="relative" maxWidth={130} marginLeft="auto" marginRight="auto" >
                <img width="100%" src={process.env.PUBLIC_URL + '/media/other/logo-cortinabanca.png'} alt={`CORTINABANCA, la "Cassa Rurale delle Dolomiti"®, è l'unica banca con sede direzionale nella Provincia di Belluno e conta 10 sportelli operativi, che coprono tutta l'area dell'Alto Bellunese, fino a Belluno, l'Alpago e Ponte nelle Alpi.`} height="auto" />
              </Pane>
            </Pane>
          </Pane>
          

        </Fragment>

        <Fragment>

          <Pane marginTop={36} alignItems="center" display="flex" flexWrap="wrap">
            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              {I18n.get('Trusted by the largest charities')}
            </Strong>
          </Pane>

          <Pane background="#FAFBFF" borderRadius={10} elevation={2} >

            <Pane alignItems="center" display="flex" flexWrap="wrap" gap={20} >

              <Pane flex={1.2} minWidth="250px" textAlign={"left"}>

                <Pane
                  borderRadius={20}
                  borderTopLeftRadius={10}
                  background="#3B8456" padding={24}
                >

                  <Pane width="fit-content" >
                    <Pane maxWidth={140}>
                      <img src={process.env.PUBLIC_URL + '/media/other/logo-hfb-white.png'} alt={"The Trussell Trust Hackney Foodbank (HFB)"}
                        // height="100%"
                        width="100%"
                      />
                    </Pane>
                  </Pane>

                  <Paragraph marginTop={20} fontWeight={600} fontStyle="italic" lineHeight={"inherit"} fontSize={20} color="#FFFFFF">
                    {I18n.get('"The platform has cut down our costs, allowed us to deliver services to the entire community and made us much more efficient!"')}
                  </Paragraph>

                  <Paragraph marginTop={40} lineHeight={"inherit"} fontSize={"1.125rem"} color="#FFFFFF">
                    {I18n.get('Pat Fitzsimons')}
                  </Paragraph>

                  <Paragraph fontWeight={700} lineHeight={"inherit"} fontSize={"1.125rem"} color="#FFFFFF">
                    {I18n.get('CEO, the Trussell Trust Hackney Foodbank')}
                  </Paragraph>

                </Pane>
                

              </Pane>

              <Pane flex={0.8} width="100%" maxHeight="300px" minWidth="250px" height="100%" padding={20} marginLeft="auto" marginRight="auto" >
                
                {/* <Fragment>
                  <Strong fontWeight={700} lineHeight={"inherit"} fontSize={70} marginY={0} color="#3B8456">
                    {I18n.get('300')}
                  </Strong>
                  <Strong fontWeight={700} lineHeight={"inherit"} fontSize={50} marginY={0} color="#3B8456">
                    {I18n.get('%')}
                  </Strong>
                  <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#3B8456">
                    {I18n.get('increase in flow-through rate of beneficiaries to value-adding services')}
                  </Paragraph>
                  
                </Fragment> */}

                <Fragment >
                  <Pane justifyContent="left" display="flex" paddingY={8} >
                    <Pane display={"flex"} marginRight={12} >
                      <MobilePhoneIcon color="#283655" size={18} />
                    </Pane>
                    <Text color="#283655" fontSize={18} >{I18n.get("No queuing at food banks")}</Text>
                  </Pane>
                  <Pane justifyContent="left" display="flex" paddingY={8} >
                    <Pane display={"flex"} marginRight={12} >
                      <ShopIcon color="#283655" size={18} />
                    </Pane>
                    <Text color="#283655" fontSize={18} >{I18n.get("Fresh and healthy food at local stores")}</Text>
                  </Pane>
                  <Pane justifyContent="left" display="flex" paddingY={8} >
                    <Pane display={"flex"} marginRight={12} >
                      <HeartIcon color="#283655" size={18} />
                    </Pane>
                    <Text color="#283655" fontSize={18} >{I18n.get("Equitable and inclusive experience")}</Text>
                  </Pane>
                  <Pane justifyContent="left" display="flex" paddingY={8} >
                    <Pane display={"flex"} marginRight={12} >
                      <HeatmapIcon color="#283655" size={18} />
                    </Pane>
                    <Text color="#283655" fontSize={18} >{I18n.get("Diverse and dignifying service")}</Text>
                  </Pane>

                  {/* <Pane justifyContent="left" display="flex" paddingY={8} >
                    <Pane display={"flex"} marginRight={12} >
                      <GlobeIcon color="#283655" size={18} />
                    </Pane>
                    <Text color="#283655" fontSize={18} >{I18n.get("No food distribution costs")}</Text>
                  </Pane> */}

                  <Pane justifyContent="left" display="flex" paddingY={8} >
                    <Pane display={"flex"} marginRight={12} >
                      <TreeIcon color="#4C9F38" size={18} />
                    </Pane>
                    <Text color="#283655" fontSize={18} >{I18n.get("No logistics and storage costs")}</Text>
                  </Pane>
                  
                </Fragment>

              </Pane>

            </Pane>

            {/* <Pane flex={1} width="100%" minWidth="250px" height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
              <Pane width="100%" maxWidth={380} padding={4} marginLeft="auto" marginRight="auto" background="tint1" borderRadius={10} textAlign="center">
                <ResponsiveEmbed src='https://www.youtube.com/embed/xbG3blXMv0M' ratio='1:1' allowFullScreen />
              </Pane>
            </Pane> */}

            <Pane
              padding={24}
              paddingTop={36}
              // maxWidth={420}
              // maxHeight={420}
              marginLeft="auto" marginRight="auto" background="tint1" borderRadius={10} textAlign="center">
              <div style={{
                position: "relative",
                paddingTop: "56.25%" // "100%" - square // "56.25%" /* Player ratio: 100 / (1280 / 720) */
              }}> 
                <ReactPlayer
                  // https://www.npmjs.com/package/react-player
                  url='https://www.youtube.com/embed/GZy2H1vIJig'  // video // 
                  controls={true}
                  width='100%'
                  height='100%'
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    // borderStyle: "solid",
                    // borderColor: "#F9F9FB",
                    // borderWidth: "1px",
                    // borderRadius: "4px",
                  }}
                  config={{
                    // youtube: {
                    //   playerVars: {
                    //     showinfo: 1,
                    //   },
                    //   embedOptions: {},
                    // },
                    // facebook: {
                    //   appId: '12345',
                    // },
                  }}
                />
              </div>
            </Pane>

            <Pane marginX={24} marginTop={0} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

            <BeneficiariesTestimonialsSection paddingX={24} paddingY={18}/>

          </Pane>

        </Fragment>


        <div ref={howWorksRef}></div>

        {/* <Pane width="100%" marginTop={56} alignItems="center" display="flex" flexWrap="wrap" gap={20} >

          <Pane flex={1} minWidth="250px" >
            
            <Pane >

              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <MobilePhoneIcon color="#283655" size={18} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("No queuing at food banks")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <ShopIcon color="#283655" size={18} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Fresh and healthy food at local stores")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <HeartIcon color="#283655" size={18} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Equitable, diverse and inclusive experience")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <GlobeIcon color="#283655" size={18} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("No food distribution costs")}</Text>
              </Pane>
              <Pane justifyContent="left" display="flex" paddingY={8} >
                <Pane display={"flex"} marginRight={12} >
                  <ShieldIcon color="#283655" size={18} />
                </Pane>
                <Text color="#283655" fontSize={18} >{I18n.get("Secure and dignifying service")}</Text>
              </Pane>

            </Pane>

          </Pane>

          

          <Pane flex={1} width="100%" minWidth="250px" height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
            <Pane width="100%" padding={4} maxWidth={420} maxHeight={420} marginLeft="auto" marginRight="auto" background="tint1" borderRadius={10} textAlign="center">
              video
            </Pane>
          </Pane>

        </Pane> */}
        
        
        {/* <Pane width="100%" marginTop={56} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane> */}

        <div ref={pricingRef}></div>

        <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap">

          <Pane flex={1.2} minWidth="250px" textAlign={"left"}>

            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              {I18n.get('Pricing')}
            </Strong>
            <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('Simple, pay-as-you-go pricing,')}
            </Paragraph>
            <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('comparable to all other fundraising platforms.')}
            </Paragraph>
            {/* <br></br> */}

          </Pane>

          <Pane flex={0.8} width="100%" marginTop={16} maxHeight="300px" minWidth="250px" height="100%" marginLeft="auto" marginRight="auto" textAlign="center">
            <Strong fontWeight={700} lineHeight={"inherit"} fontSize={"7.5rem"} marginY={0} color="#283655">
              {I18n.get('3.9')}
            </Strong>
            <Strong fontWeight={700} lineHeight={"inherit"} fontSize={"5rem"} marginY={0} color="#283655">
              {I18n.get('%')}
            </Strong>
            <br></br>
            <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} marginY={0} color="#283655">
              {" + £0.20"} {I18n.get('per donation')}
            </Paragraph>
          </Pane>

        </Pane>


        <Pane width="100%" marginTop={56} maxWidth={780} padding={12} paddingTop={is426PlusScreen ? 0 : 12} marginLeft="auto" marginRight="auto" borderRadius={20} textAlign="center" background="#FAFBFF" elevation={2}>
          <Pane flex={1} width="100%" alignItems="center" display={is426PlusScreen ? "flex" : "none"} flexWrap="wrap" minWidth={250} justifyContent="center" >
            <Pane flex={1} marginTop={12} width="100%" minWidth={140} maxWidth={200} background="#DDEBF7" borderRadius={10} padding={10} textAlign="center">
              <Strong fontSize={18} color="#1070CA" >{I18n.get('Transparency')}</Strong>
            </Pane>
            <Pane flex={1} marginTop={12} marginX={36} width="100%" maxWidth={40} borderRadius={10} padding={10} textAlign="center">
              <Strong fontSize={18} color="#283655" >{"+"}</Strong>
            </Pane>
            <Pane flex={1} marginTop={12} minWidth={140} width="100%" maxWidth={200} background="#D4EEE2" borderRadius={10} padding={10} textAlign="center">
              <Strong fontSize={18} color="#00783E" >{I18n.get('Efficiency')}</Strong>
            </Pane>
            <Pane flex={1} marginTop={12} marginX={36} width="100%" maxWidth={40} borderRadius={10} padding={10} textAlign="center">
              <Strong fontSize={18} color="#283655" >{"="}</Strong>
            </Pane>
            <Pane flex={1} marginTop={12} minWidth={140} width="100%" maxWidth={200} background="#EAE7F8" borderRadius={10} padding={10} display="flex" justifyContent="center" textAlign="center">
              <Strong fontSize={18} color="#37248F" >{I18n.get('Trust')} </Strong>
              <Icon marginLeft={10} icon={HeartIcon} color="danger" size={20}/>
            </Pane>
          </Pane>
        </Pane>

        <Pane marginTop={56} >

          <Pane textAlign={"center"} marginLeft="auto" marginRight="auto" >

            <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={36} marginY={20} color="#283655">
              {I18n.get('Start making more impact today')} {/* Getting started is easy */}
            </Strong>
            {/* <Paragraph lineHeight={"inherit"} fontSize={"1.125rem"} color="#283655">
              {I18n.get('Start making more impact today.')}
            </Paragraph> */}

          </Pane>

          <Pane width="100%" marginTop={36} alignItems="center" display="flex" flexWrap="wrap" justifyContent={"center"} gap={20} >

            <Pane textAlign="center" >
              <Button
                fontSize={18} paddingX={24} minWidth={140} height={48} justifyContent="center"
                className='button-blue'
                is={Link}
                to={{
                  pathname: generatePath("/:page", {...match.params, page: "sign-up"}),
                  // state: {},
                }}
              >
                {I18n.get('Create a free account')}
              </Button>
            </Pane>

            <Pane textAlign="center" >
              <Button
                appearance="minimal"
                fontSize={18} paddingX={24} minWidth={140} height={48} justifyContent="center"
                className="noselect"
                is={Link}
                to={{
                  pathname: generatePath("/:page", {page: "email"}),
                  // state: {},
                }}
              >
                {I18n.get('Contact us')}
              </Button>
            </Pane>

          </Pane>

        </Pane>
        
        <GoodCompanySection marginTop={56}/>
        

        {/*
        <Pane width="100%" marginTop={36} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" ></Pane>

        <Pane width="100%" marginTop={36} alignItems="center" textAlign="center" padding={10} >
          <div ref={howWorksRef}></div>
          <Heading size={800} color="#283655">{tHowItWorks}</Heading>
          <SegmentedControl
            marginTop={8}
            marginLeft="auto"
            marginRight="auto"
            height={40}
            width={240}
            options={explainerFor.options}
            value={explainerFor.value}
            onChange={(value) => {setExplainerFor({ ...explainerFor, value: value})}}
          />
        </Pane>

        {explainerFor.value === "forProviders" ?
          <ExplainerForProviders />
          :
          <ExplainerForDonors />
        }

        */}

        <Pane width="100%" marginTop={56} borderTop borderTopWidth={1} borderTopStyle="solid" borderColor="#283655" ></Pane>

        <div ref={footerRef}></div>
        <div ref={socialsRef}></div>
        <div ref={sitemapRef}></div>
        


        <Pane is="footer" >
          <Pane is="nav" aria-label="Site" >
            <Pane width="100%" marginTop={36} alignItems="flex-start" display="flex" flexWrap="wrap" >

              <Pane flex={0.25} minWidth={230} >
                    
                <Pane >
                  <Button
                    flex="none"
                    padding={4}
                    appearance="minimal"
                    lineHeight={"inherit"}
                    height={"auto"}
                    // onClick={() => {
                    //   if (userState?.user) {
                    //     history.push(generatePath("/:locale?/:handle", {...match.params, handle: userState?.user?.username}));
                    //   }
                    //   else {
                    //     history.push(generatePath("/:locale?", {...match.params}));
                    //   }
                    // }}
                    is={Link}
                    to={{
                      pathname: generatePath("/:locale?", {...match.params}),
                      // state: {},
                    }}

                    // style={{
                    //   "color": "#283655",
                    //   "fontWeight": "400",
                    //   "height": "auto",
                    //   "lineHeight": "inherit"
                    // }}
                    justifyContent="center"
                  >
                  <Pane display="flex" flexWrap="wrap" alignItems="center" >
                    <Pane width={36} height={36} padding={0} marginLeft="auto" marginRight="auto">
                      <Fd96 width="100%" height="100%" />
                    </Pane>
                    {/* <Pane marginLeft={6} paddingTop={6} paddingBottom={4} paddingX={4} >
                      <Pane width="100%" padding={0} marginBottom={2} height={2} background="#FF9C8F" borderRadius={1} ></Pane>
                        <Heading size={600} color="#283655" marginTop="auto" marginBottom="auto" borderRadius={10} >FACE DONATE</Heading>
                      <Pane width="100%" padding={0} marginTop={2} height={2} background="#FF9C8F" borderRadius={1} ></Pane>
                    </Pane> */}
                  </Pane>
                  </Button>
                </Pane>
                
                <Pane marginTop={16} >

                  <Pane >
                    {/* <Pane display="inline-flex" >
                      <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#283655"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://twitter.com/face_donate"} target={"_blank"} onClick={() => {}}>
                        <TwitterIcon />
                      </DynamicIconButton>
                    </Pane> */}
                    <Pane display="inline-flex">
                      <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#283655"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.facebook.com/facedonate.org"} target={"_blank"} onClick={() => {}}>
                        <FacebookIcon />
                      </DynamicIconButton>
                    </Pane>
                    {/* <Pane display="inline-flex">
                      <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#283655"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://facedonate.org/face_donate"} target={"_blank"} onClick={() => {}}>
                        <FacedonateIcon />
                      </DynamicIconButton>
                    </Pane> */}
                    <Pane display="inline-flex">
                      <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#283655"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.instagram.com/face_donate"} target={"_blank"} onClick={() => {}}>
                        <InstagramIcon />
                      </DynamicIconButton>
                    </Pane>
                    <Pane display="inline-flex">
                      <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#283655"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.linkedin.com/company/facedonate"} target={"_blank"} onClick={() => {}}>
                        <LinkedinIcon />
                      </DynamicIconButton>
                    </Pane>
                    <Pane display="inline-flex">
                      <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#283655"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.youtube.com/@face_donate"} target={"_blank"} onClick={() => {}}>
                        <YoutubeIcon />
                      </DynamicIconButton>
                    </Pane>
                  </Pane>
                  
                  
                </Pane>

                <Pane marginTop={16} >
                  <Pane flex={"auto"} >
                    <Button
                      style={{
                        color: "#283655",
                      }}
                      appearance="minimal"
                      fontSize={"0.875rem"} paddingX={8}
                      height={32}
                      justifyContent="center"
                      onClick={() => {

                      }}
                      // is={Link}
                      // to={{
                      //   pathname: "/", // generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                      //   // state: {},
                      // }}
                    >
                      <GlobeNetworkIcon color="#283655" size={16} marginRight={8} />
                      {I18n.get('Language')}
                      <ChevronDownIcon color="#283655" size={12} marginLeft={6} />
                    </Button>
                    
                  </Pane>
                </Pane>

              </Pane>

              <Pane flex={1} >

                <Pane width="100%" alignItems="flex-start" display="flex" flexWrap="wrap" gap={0} >

                  <Pane flex={1} minWidth={230} >

                    <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} paddingX={4} fontSize={16} marginY={8} color="#283655">
                      {I18n.get('Platform')}
                    </Strong>
                    
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "overview"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Overview')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "pricing"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Pricing')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "donations"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Donations')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "feedback"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Feedback')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "fundraising"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Fundraising')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "vouchers"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Digital vouchers')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "dashboard"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Impact dashboard')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "impact-reporting"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('ESG reporting')}
                    </FooterNavButton>

                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "widget"}),
                        // state: {},
                      }}
                      disabled={true}
                      isSoon={true}
                    >
                      {I18n.get('Impact widget')}
                    </FooterNavButton>
                    
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "docs"}),
                        // state: {},
                      }}
                      disabled={true}
                      isSoon={true}
                    >
                      {I18n.get('API docs')}
                    </FooterNavButton>

                  </Pane>

                  <Pane flex={1} minWidth={230} >

                    <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={16} paddingX={4} marginY={8} color="#283655">
                      {I18n.get('Solutions')}
                    </Strong>

                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "personal"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Personal giving')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "corporate"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Corporate giving')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "charities"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Charities')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "food-banks"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Food banks')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "beneficiaries"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Beneficiaries')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "retailers"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('Retailers')}
                    </FooterNavButton>
                    
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "impact-reporting"}),
                        // state: {},
                      }}
                    >
                      {I18n.get('ESG and CSR')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "foundations"}),
                        // state: {},
                      }}
                      disabled={true}
                      isSoon={true}
                    >
                      {I18n.get('Foundations')}
                    </FooterNavButton>
                    <FooterNavButton
                      to={{
                        pathname: generatePath("/:page", {page: "government"}),
                        // state: {},
                      }}
                      disabled={true}
                      isSoon={true}
                    >
                      {I18n.get('Government')}
                    </FooterNavButton>

                  </Pane>

                  <Pane flex={1} minWidth={230} >

                    <Pane >

                      <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={16} paddingX={4} marginY={8} color="#283655">
                        {I18n.get('Get started')}
                      </Strong>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "demo"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Request a demo')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "switch"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Switch from JustGiving')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "switch"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Switch from GoFundMe')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "sign-up"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Sign up')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "log-in"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Log in')}
                      </FooterNavButton>

                    </Pane>


                    <Pane >

                      <Strong is={"h3"} fontWeight={700} lineHeight={"inherit"} fontSize={16} paddingX={4} marginY={8} color="#283655">
                        {I18n.get('Resources')}
                      </Strong>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "about"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('About us')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "faq"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('FAQ')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "media-kit"}),
                          // state: {},
                        }}
                        disabled={true}
                        isSoon={true}
                      >
                        {I18n.get('Media kit')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "legal"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Cookie settings')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "legal"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Terms & privacy')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "email"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Email us')}
                      </FooterNavButton>

                      <FooterNavButton
                        to={{
                          pathname: generatePath("/:page", {page: "complaints"}),
                          // state: {},
                        }}
                      >
                        {I18n.get('Complaints')}
                      </FooterNavButton>

                    </Pane>

                  </Pane>

                </Pane>

                <Pane paddingY={12} marginBottom={24} >
                
                  <Pane justifyContent="left" display="flex" alignItems="center" paddingY={2} >
                    <Pane display={"flex"} marginRight={4} >
                      <ShieldIcon color="#7B8B9A" size={"0.75rem"} />
                    </Pane>
                    <Text fontWeight={600} fontSize={"0.75rem"} color="#7B8B9A" >
                      {I18n.get("We do not sell or share your personal information")}
                    </Text>
                  </Pane>

                  {/* <Pane paddingY={2} > </Pane> */}

                  <Pane paddingY={2} >
                    <Text fontSize={"0.75rem"} color="#7B8B9A" className="text-link"
                      is={Link}
                      to={{
                        pathname: generatePath("/:locale?", {...match.params}),
                        // state: {},
                      }}
                    >
                      {"© 2019-2024 FACE DONATE ™"}
                    </Text>
                  </Pane>
                
                </Pane>

              </Pane>

            </Pane>
          
          </Pane>
        </Pane>
      </Pane>
      

      {/* <Pane background="#283655" paddingY={12}>

        <Pane maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" >

          <Pane width="100%" alignItems="center" display="flex" flexWrap="wrap" gap={20} >

            <Pane flex={"auto"} >

              <Pane paddingY={8} >
                <Text fontSize={"0.875rem"} color="#FFFFFF" className="text-link"
                  is={Link}
                  to={{
                    pathname: generatePath("/:locale?", {...match.params}),
                    // state: {},
                  }}
                >
                  {"© 2024 FACE DONATE ™"}
                </Text>
              </Pane>

            </Pane>

            

            <Pane flex={"auto"} >
              <Button
                style={{
                  color: "#FFFFFF",
                }}
                appearance="minimal"
                fontSize={"0.875rem"} paddingX={8}
                height={32}
                justifyContent="center"
                // className='button-blue'
                is={Link}
                // to={{
                //   pathname: generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                //   // state: {},
                // }}
              >
                <GlobeNetworkIcon color="#FFFFFF" size={16} marginRight={8} />
                {I18n.get('Language')}
                <ChevronDownIcon color="#FFFFFF" size={12} marginLeft={6} />
              </Button>
              
            </Pane>

            <Pane flex={"auto"} >
              <Button
                style={{
                  color: "#FFFFFF",
                }}
                appearance="minimal"
                fontSize={"0.875rem"} paddingX={8}
                height={32}
                justifyContent="center"
                // className='button-blue'
                is={Link}
                // to={{
                //   pathname: generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                //   // state: {},
                // }}
              >
                {I18n.get('Terms')}
              </Button>
              
            </Pane>

            <Pane flex={"auto"} >
              <Button
                style={{
                  color: "#FFFFFF",
                }}
                appearance="minimal"
                fontSize={"0.875rem"} paddingX={8}
                height={32}
                justifyContent="center"
                // className='button-blue'
                is={Link}
                // to={{
                //   pathname: generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                //   // state: {},
                // }}
              >
                {I18n.get('Privacy')}
              </Button>
              
            </Pane>

            <Pane flex={"none"} >
              <Pane display="inline-flex" >
                <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#FFFFFF"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://twitter.com/face_donate"} target={"_blank"} onClick={() => {}}>
                  <TwitterIcon />
                </DynamicIconButton>
              </Pane>
              <Pane display="inline-flex">
                <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#FFFFFF"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.facebook.com/facedonate.org"} target={"_blank"} onClick={() => {}}>
                  <FacebookIcon />
                </DynamicIconButton>
              </Pane>
              <Pane display="inline-flex">
                <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#FFFFFF"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://facedonate.org/face_donate"} target={"_blank"} onClick={() => {}}>
                  <FacedonateIcon />
                </DynamicIconButton>
              </Pane>
              <Pane display="inline-flex">
                <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#FFFFFF"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.instagram.com/face_donate"} target={"_blank"} onClick={() => {}}>
                  <InstagramIcon />
                </DynamicIconButton>
              </Pane>
              <Pane display="inline-flex">
                <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#FFFFFF"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.linkedin.com/company/facedonate"} target={"_blank"} onClick={() => {}}>
                  <LinkedinIcon />
                </DynamicIconButton>
              </Pane>
              <Pane display="inline-flex">
                <DynamicIconButton size={is426PlusScreen ? 20 : 18} color={"#FFFFFF"} padding={6} onHoverColor={"#7B8B9A"} onClickColor={null} href={"https://www.youtube.com/@face_donate"} target={"_blank"} onClick={() => {}}>
                  <YoutubeIcon />
                </DynamicIconButton>
              </Pane>
            </Pane>

          </Pane>
        
          
        </Pane>

      </Pane> */}
      

      <Footer isVisible={false}/>
      

    </Pane>
  );
}

export default Home;