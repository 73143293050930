import { useEffect, useLayoutEffect, useState, useCallback, useMemo, useRef, Fragment } from 'react';

import { Link, Redirect, generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { pathToRegexp, compile, match as matchPTR } from 'path-to-regexp';
import { Strong, Text, Button, Pane, Icon, CircleArrowLeftIcon } from 'evergreen-ui';


import { API, I18n } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

import Footer from '../components/Footer';

import CameraComponent from '../components/CameraComponent';

import { useSelector, useDispatch, batch } from 'react-redux';
import { updateUserState, setProfileDetails, setTransactionDetails, setDialogState, updateDialogState, updateTransactionDetails } from '../services/actions';

const CameraPage = () => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);

  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log("CameraPage", countRef.current)
    return () => {
      console.log("CameraPage cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  },[location.search]);

  const dispatch = useDispatch();

  const userState = useSelector(state => state.userState);
  const browserInfo = useSelector(state => state.localeState.browserInfo);
  const transactionDetails = useSelector(state => state.transactionDetails);
  // const dialogState = useSelector(state => state.dialogState);
  const localeState = useSelector(state => state.localeState);

  const userDetails = useMemo(() => {
    return(userState.actAsUser || userState.user);
  }, [userState]);

  const cameraRef = useRef(null);

  const [processing, setProcessing] = useState(false);

  // return to pos provision page 
  useEffect(() => {
    if (queryParams.get('camera') === "payment_qr_code" && !transactionDetails.history?.find((t) => (t.type === "provisionReceipt"))?.proofImages?.length) {
      queryParams.delete('camera');
      history.replace({
        search: `?${queryParams.toString()}`,
      });
    }
  },);

  const handleCapturedCameraData = useCallback(async({ pictureB64, qrCodeData }) => {

    setProcessing(true);

    if (qrCodeData) {
      
      function domain_from_url(url) {
        let result;
        let match;
        if ((match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im))) {
            result = match[1]
            if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
                result = match[1]
            }
        }
        return result;
      }


      const domain = domain_from_url(qrCodeData);
      // console.log("domain", domain);

      if (domain && domain.toLowerCase() === "facedonate.org") {

        function getMatchDetails(path, uri) {
          // start: false makes parsing of optional params work
          const matchPath = matchPTR(path, { decode: decodeURIComponent, start: false, end: false });
          const matchDetails = matchPath(uri);
          return matchDetails;
        }
  
        let matchDetails = null;
        if ((matchDetails = getMatchDetails(`/:domain(${domain})/:page(qr)/:mode(p|r)/:publicTransactionId/:accessCode`, qrCodeData))) {
          console.log("matchDetails", matchDetails);
          const params = matchDetails.params;
          
          if (params.mode === "p") {
            
            // const { data, error } = handleAccessTransaction({
            //   publicTransactionId: params.publicTransactionId,
            //   accessCode: params.accessCode,
            // });
            // if (error) {
            //   // handle error
            // }
            // else {
            //   console.log("data", data)
            //   // combine this dispatch with the below dispatch ???
            //   batch(() => {
            //     dispatch(setProfileDetails(tempRecipient));
            //     dispatch(updateTransactionDetails(tempTransaction));
            //   });

            //   dispatch(updateTransactionDetails({
            //     ???: "ViewNotRecognisedFace",
            //   }));

            //   history.replace({
            //     pathname: generatePath("/:locale?/:handle", {...match.params, handle: tempRecipient.username}),
            //     // state: {}
            //   });
            // }

            dispatch(updateDialogState({
              qrCodeDataToProcess: null
            }));
            
          }
          else if (params.mode === "r") {
            // process request for payment ???

            dispatch(updateDialogState({
              qrCodeDataToProcess: null
            }));
          }

        }
        else if ((matchDetails = getMatchDetails(`/:domain(${domain})/:rest(.*)?`, qrCodeData))) {
          // go to path, wherever it takes on the domain...
          console.log("qrCodeData", qrCodeData);
          console.log("matchDetails", matchDetails);
          const params = matchDetails.params;

          history.push(`/${params.rest}`);
        }
        else {
          // QR code is process... no action recognised
          dispatch(updateDialogState({
            qrCodeDataToProcess: null
          }));
        }
      }
      else {
        // Error... unsupported QR code ???
      }
      setProcessing(false);
      
    }
    else if (pictureB64) {

      try {

        const definedProps = obj => Object.fromEntries(
          Object.entries(obj).filter(([k, v]) => v)
        );

        // generate transaction if no existing
        if (transactionDetails.status === "auth") {
          
          if (!transactionDetails.publicTransactionId) {
            let toPublicFaceId = null;
            let fromPublicFaceId = null;
            
            switch (transactionDetails.type) {
              case "paymentIntent":
                toPublicFaceId = null;
                fromPublicFaceId = userDetails?.publicFaceId;
                break;
              case "provisionIntent": // not sure what this is for ???
                toPublicFaceId = null;
                fromPublicFaceId = userDetails?.publicFaceId;
                break;

              default:
                // send to error view ?
                break;
            }
            const response = await API.graphql({
              query: mutations.generateTransaction,
              variables: {
                toPublicFaceId: toPublicFaceId,
                fromPublicFaceId: fromPublicFaceId,
                type: transactionDetails.type,
                amount: transactionDetails.amount,
                currency: userDetails?.balance.currency, // ???
                faceB46: pictureB64,
              },
              authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
            });

            let generatedTransaction = response.data.generateTransaction;
            // console.log("generatedTransaction", generatedTransaction);

            batch(() => {
              dispatch(updateTransactionDetails({
                ...definedProps(generatedTransaction),
              }));
            });

          }
          else {
            // not sure ???
            const response = await API.graphql({
              query: mutations.accessTransaction,
              variables: {
                publicTransactionId: transactionDetails.publicTransactionId,
                accessCode: transactionDetails.accessCode,
                faceB46: pictureB64, // improvement ???
              },
              authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
            });
            const accessedTransaction = response.data.accessTransaction;
            
            // console.log("accessedTransaction", accessedTransaction);
            // transaction is updated via subscription // ???
            // dispatch(updateTransactionDetails(accessedTransaction)); // ???
          }

        }
        else {
          
          const response = await API.graphql({
            query: mutations.recogniseFace,
            variables: {
              faceB46: pictureB64,
              currency: localeState.currency,
              // locale: null,
            },
            authMode: userDetails ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
          });
          console.log("recogniseFace response", response.data.recogniseFace);
          let faceDetails = response.data.recogniseFace;

          if (!faceDetails) {
            // handle is nothing is return ???
            // retry ???
            setProcessing(false);
            return null;
          }
          
          dispatch(setDialogState()); // this will close the dialog

          if (faceDetails.status === "new") { // ???
            // console.log("go to try again");
            // dispatch(updateTransactionDetails({
            //   // ???: "ViewNotRecognisedFace",
            //   localPicture: await drawBoundingBoxToFace(value.pictureB64, tempRecipient.objectDetails.SearchedFaceBoundingBox),
            // }));
          }
          else if (faceDetails.status === "readyToRegister") { // ???
            // console.log("go to register a new Face");
            // dispatch(updateTransactionDetails({
            //   // ???: "ViewRegisterNewFace",
            //   localPicture: await drawBoundingBoxToFace(value.pictureB64, tempRecipient.objectDetails.SearchedFaceBoundingBox),
            // }));
          }
          else {
            // go to found Profile page
            history.push({
              pathname: generatePath("/:locale?/:handle", {...match.params, handle: faceDetails.username}),
              // state: {}
            });
          }
        }
        dispatch(updateDialogState({
          pictureB64ToProgress: null
        }));
        setProcessing(false);
      }
      catch (error) {
        console.error("within generateTransaction or recogniseFace:", error);
        dispatch(updateDialogState({
          pictureB64ToProgress: null,
        }));
        dispatch(updateTransactionDetails({
          localError: error,
        }));
        setProcessing(false);
      }
    }

  }, [dispatch, history, localeState.currency, match.params, transactionDetails.accessCode, transactionDetails.amount, transactionDetails.publicTransactionId, transactionDetails.status, transactionDetails.type, userDetails]);

  const handleOnChange = useCallback(async({ pictureB64, qrCodeData }) => {

    console.log("qrCodeData", qrCodeData);

    // async function wait(ms) {
    //   return new Promise(resolve => {
    //     setTimeout(resolve, ms);
    //   });
    // }

    // console.log("wait", 0);
    // await wait(5000);
    // console.log("wait", 5000);

    // dispatch(updateTransactionDetails({
    //   localError: {
    //     message: "Invalid QR code.",
    //   },
    // }));

    if (queryParams.get('camera') === "payment_qr_code") {

      queryParams.delete('camera');

      function getMatchDetails(path, uri) {
        // start: false makes parsing of optional params work
        const matchPath = matchPTR(path, { decode: decodeURIComponent, start: false, end: false });
        const matchDetails = matchPath(uri);
        return matchDetails;
      }
      let matchDetails = getMatchDetails(`/:page(qr)/:mode(p|r)?/:publicTransactionId?/:accessCode?/:amount?/:currency?`, qrCodeData);
      // console.log("matchDetails", matchDetails);
      if (matchDetails) {
        const params = matchDetails.params;
        if (params.page === "qr" && params.mode === "p") { // p - payment

          queryParams.set('payment_code', params.publicTransactionId);
          queryParams.set('access_code', params.accessCode);

          // console.log("payment_code", params.publicTransactionId);
          // console.log("access_code", params.accessCode);

          // console.log("amount in scanned QR code", params.amount);
          // console.log("currency in scanned QR code", params.currency);
          
        }
      }
      else {
        dispatch(updateTransactionDetails({
          localError: {
            message: "Invalid QR code.",
          },
        }));
      }

      history.replace({
        search: `?${queryParams.toString()}`,
      });
      
      // setIsCameraProcessing(false);

    }
    else if (match.params.page === "camera" || queryParams.get('camera') === "qr_code") {
      handleCapturedCameraData({
        qrCodeData: qrCodeData,
        // pictureB64: pictureB64
      });
    }

    // setProcessing(true);

  }, [dispatch, handleCapturedCameraData, history, match.params.page, queryParams]);

  const [pageTitle, setPageTitle] = useState();
  // const [canGoBack, setCanGoBack] = useState(false);
  const [isCameraUserFacing, setIsCameraUserFacing] = useState(false);
  const [isCameraScanningQrCodes, setIsCameraScanningQrCodes] = useState(true);
  const [isCameraShowingButtons, setIsCameraShowingButtons] = useState(false);

  useLayoutEffect(() => {

    // if (queryParams.get('camera')) {
    //   setCanGoBack(true);
    // }
    // else {
    //   setCanGoBack(false);
    // }

    if (match.params.page === "camera" || queryParams.get('camera') === "payment_qr_code" || queryParams.get('camera') === "qr_code") {
      setPageTitle(I18n.get('Scan QR code'));
      setIsCameraUserFacing(false);
      setIsCameraScanningQrCodes(true);
    }
    else if (queryParams.get('camera') === "auth") {
      setPageTitle(I18n.get('Face ID'));
      setIsCameraUserFacing(true);
      setIsCameraScanningQrCodes(false);
      setIsCameraShowingButtons(true);
    }
    else {
      setPageTitle(I18n.get('Camera'));
      setIsCameraUserFacing(false);
      setIsCameraScanningQrCodes(false);
      setIsCameraShowingButtons(false);
    }
    
  }, [match.params.page, queryParams]);

  
  return (
    <Pane >
      <Pane clearfix maxWidth={960} min-height="100vh" paddingX={12} alignItems="center" marginLeft="auto" marginRight="auto" paddingBottom={150}>

        <Pane width="100%" marginTop={12}>

          <Pane maxWidth={630} marginLeft="auto" marginRight="auto" >

          <Pane width="100%" marginTop={12} marginBottom={0} alignItems="center" display="flex" flexWrap="wrap">
          
            <Pane flex={1} marginTop={10} alignItems="center" display="flex" flexWrap="wrap"  >
              <Pane flex={"none"} paddingRight={10} >
                <Button
                  disabled={false}
                  height={"auto"}
                  padding={3}
                  borderRadius={"50%"}
                  // appearance="minimal"
                  onClick={() => {
                    if (queryParams.get('camera')) {
                      queryParams.delete('camera');
                      history.push({
                        search: `?${queryParams.toString()}`,
                      });
                    }
                    else {
                      if (userDetails) {
                        history.push(generatePath("/:handle", {...match.params, handle: userDetails?.username}));
                      }
                      else {
                        history.push(generatePath("/", {...match.params}));
                      }
                    }

                  }}
                >
                  <Icon icon={CircleArrowLeftIcon} size={28} color="#425A70" />
                </Button>
                
              </Pane>
              <Pane flex={1} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" />
            </Pane>

            <Strong flex={"none"} fontSize={24} marginX={10} height="1em" lineHeight={1.3} color="#283655" >{pageTitle}</Strong>

            <Pane flex={1} marginTop={10} borderTop borderTopWidth={1} borderTopStyle="dashed" borderColor="#7B8B9A" />

          </Pane>

          {queryParams.get('camera') === "auth" ? 
            <Fragment>
              <Pane marginTop={10} textAlign="center" >
                <Strong fontSize={16} color="#283655">{I18n.get('Take a picture of your face to proceed.')}</Strong>
              </Pane>
              <Pane textAlign="center" marginTop={4} >
                <Text fontSize={14} color={"#7B8B9A"} >{I18n.get('The process is 100% safe and secure.')}</Text>
              </Pane>
            </Fragment>
          : null }



            <Pane marginTop={12} borderRadius={5} background="#E4E7EB" >
              <CameraComponent
                ref={cameraRef}
                isOn={true}
                isUserFacing={isCameraUserFacing}
                scanQrCodes={isCameraScanningQrCodes}
                // scanQrBox={transactionDetails.status === "auth" ? false : true}
                // ratio={transactionDetails.status === "auth" ? 1/1 : 4/5}
                dict={{
                  'Open camera': I18n.get('Open camera'),
                  'Retake': I18n.get('Retake'),
                  'Use': I18n.get('Use photo'),
                }}
                // scanQrBoxColor = {"#FF9C8F"}
                // placeholderIsShown={(transactionDetails.status === "auth")}
                // pictureB64={}
                // overlay={}
                placeholderQrCode={true}
                showButtons={isCameraShowingButtons}
                showTakenPicture={true}
                isProcessing={processing}
                showRetakeButton={true} // remove ???
                onChange={handleOnChange}
                maxPictureWidth={1350}
                maxPictureHeight={1350}
                onClose={null}
                // showSystemParams={true} // match.params.page === "system"
                
              />
            </Pane>

          </Pane>

          <Pane marginTop={20} borderRadius={10} padding={8} background="#FAFBFF" >
            <Pane >
              <Strong fontSize={14} color="#425A70" >{I18n.get('System parameters')}</Strong>
            </Pane>
            
            <Pane >
              <Text fontSize={14} color="#283655" >{
                `${"User"}: ${userDetails?.publicFaceId || "n.a."}`
              }</Text>
            </Pane>
            
            {Object.entries(browserInfo).map(([key,value], i) => (
              <Pane key={i} >
                <Text fontSize={14} color="#283655" >{
                  `${key}: ${value}`
                }</Text>
              </Pane>
            ))}
          </Pane>
          

        </Pane>

      </Pane>

      {/* <Footer/> */}
      
    </Pane>
  );
}

export default CameraPage;