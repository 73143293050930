import { Fragment, useEffect, useState, useRef, useMemo } from 'react';

import { Link, useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { Text, Pane, Popover, Menu, Position, Button } from 'evergreen-ui';

import { I18n } from 'aws-amplify';

import CookiesConsent from './CookiesConsent';
import PoorConnection from './PoorConnection';
import PickLanguage from './PickLanguage';

const Footer = ({
  isVisible = true,
  ...rest
}) => {

  const countRef = useRef(0);
  const isCurrent = useRef(true);
  useEffect(() => {
    countRef.current = countRef.current + 1;
    console.log(`Footer - ${countRef.current}`);
    return () => {
      console.log("Footer - cleaned up");
      isCurrent.current = false;
    }
  }, []);

  let match = useRouteMatch();

  return (
    <Fragment>
      {!isVisible ? null :
        <Pane clearfix width="100%" position="absolute" bottom={0} >
          <Pane minWidth={320} maxWidth={960} paddingX={10} marginLeft="auto" marginRight="auto" marginTop={36} >

            <Pane alignItems="center" margin="auto" textAlign="center">

              <Pane marginTop={10} marginBottom={10} alignItems="center" display="flex" flexWrap="wrap">
                <Pane flex={1} textAlign="center" >
                  <PickLanguage fontColor="#425A70" fontSize={12} />
                </Pane>
              </Pane>
              
              <Pane >
                <Text size={300}>{I18n.get('Say hello at')} <a style={{ color: '#425A70'}} className='text-link' href={"mailto:hello@facedonate.org"}>{"hello@facedonate.org"}</a></Text>
              </Pane>

              <Pane>
                <Pane>
                  {/* <a className="text-link" href={match.params.locale ? `/${match.params.locale}/mission` : "/mission"} target="_blank" rel="noopener noreferrer" >
                    <Text size={300} >{tMission}</Text>
                  </a>
                  <Text size={300} > | </Text> */}
                  <Text fontSize={12} color="#425A70" className="text-link"
                    is={Link}
                    target="_blank"
                    to={{
                      pathname: generatePath("/:locale?/:page", {...match.params, page: "browse"}),
                      // state: {},
                    }}
                  >
                    {I18n.get('Browse')}
                  </Text>

                  {/* <Text size={300} > | </Text> */}
                  {/* <Text fontSize={12} color="#425A70" className="text-link"
                    is={Link}
                    target="_blank"
                    to={{
                      pathname: generatePath("/:locale?/:page/:mode", {...match.params, page: "directory", mode: "profiles"}),
                      // state: {},
                    }}
                  >
                    {I18n.get('Directory')}
                  </Text> */}

                  <Text size={300} > | </Text>
                  <Text fontSize={12} color="#425A70" className="text-link"
                    is={Link}
                    target="_blank"
                    to={{
                      pathname: generatePath("/:locale?/:page", {...match.params, page: "legal"}),
                      // state: {},
                    }}
                  >
                    {I18n.get('Legal')}
                  </Text>
                  <Text size={300} > | </Text>
                  <Text fontSize={12} color="#425A70" className="text-link"
                    is={Link}
                    target="_blank"
                    to={{
                      pathname: generatePath("/:locale?/:page", {...match.params, page: "faq"}),
                      // state: {},
                    }}
                  >
                    {I18n.get('FAQ')}
                  </Text>
                </Pane>
                <Pane paddingY={8} >
                  <Text fontSize={12} color="#425A70" className="text-link"
                    is={Link}
                    to={{
                      pathname: generatePath("/:locale?", {...match.params}),
                      // state: {},
                    }}
                  >
                    {"© 2019-2024 FACE DONATE ™"}
                  </Text>
                </Pane>
              </Pane>

            </Pane>
          </Pane>

          

        </Pane>
      }

      <CookiesConsent />
      <PoorConnection />

    </Fragment>
  )
}

export default Footer;